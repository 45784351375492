import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import router from './router'
import store from './store';
import './registerServiceWorker'
import setupInterceptors from "@/service/interceptors.service";
import AddToHomeScreen from "a2hs.js"

AddToHomeScreen({
  brandName: "Supervivientes Cáncer",
  backgroundColor: "#05904a",
  color: "#ffffff",
  htmlContent: "<b>Descargar la aplicación:</b></br> Click sobre compartir, baja a opciones y pulsa sobre <strong>AÑADIR a pantalla de inicio</strong> ↓",
  logoImage: " "
});

setupInterceptors(store)
Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
