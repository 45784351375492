<template>
	<section class="content-page-section" :class="{ active: isActive }">
		<header class="content-page-section-header" @click="setActive()">
			<h3>{{ title }}</h3>
		</header>

		<div class="content-page-section-content">
			<ul>
				<li v-for="file in list" :key="file.id">
					<a :href="file.link" target="_blank">{{ file.title }}</a>
				</li>
			</ul>
		</div>
	</section>
</template>

<script>
export default {
	name: 'ContentPageGuias',
	data() {
		return {
			isActive: false,
		}
	},
	props: {
		title: String,
		list: Array,
	},
	methods: {
		setActive() {
			this.isActive = !this.isActive
		},
	},
}
</script>

<style scoped>
.content-page-section {
	width: 100%;
	max-height: 60px;
	overflow: hidden;
	margin: 40px 0 0 0;
	transition: max-height 0.4s;
}

.content-page-section.active {
	transition: max-height 0.4s ease;
	max-height: 500vh;
}

.content-page-section-header {
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	background-color: #43955d;
	color: #fff;
	padding: 0 40px;
	cursor: pointer;
	transition: background 0.3s ease;
	position: relative;
}

.content-page-section-header:hover {
	background-color: #387d4e;
}

.content-page-section-header h3 {
	font-size: 20px;
	font-weight: 500;
	padding: 0 70px 0 0;
}

.content-page-section-header:before {
	content: '+';
	position: absolute;
	right: 60px;
	font-size: 35px;
}

.active .content-page-section-header:before {
	content: '-';
}

.content-page-section-content {
	padding: 30px 40px;
}

.content-page-section-content ul li{
  padding: 5px 0;
}

.content-page-section-content ul li a{
}
</style>
