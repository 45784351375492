<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#D22630'"
			:title="'AREA PRIVADA'"
			:subtitle="'Herramientas para el día a día.'"
			:img="'login_blanco'"
		/>

		<div v-if="loading">
			<div class="text-center">
				<v-progress-circular
					indeterminate
					color="#D22630"
					class="mt-6"
				></v-progress-circular>
			</div>
		</div>

		<div v-else-if="events.length <= 0 || error">
			<EmptyState />
		</div>
    <div else>
      <h1 class="page-title private-color px-4 pt-4">Agenda</h1>
		<v-list class="pt-0" two-line>
			<v-list-item-group>
				<template v-for="(item, index) in events">
					<v-list-item :key="item.id" :to="`/private/events/${item.id}`">
						<v-list-item-avatar>
							<v-icon class="grey lighten-1" dark> fa-calendar </v-icon>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title v-text="item.date"></v-list-item-title>

							<v-list-item-subtitle v-text="item.name"></v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-action> </v-list-item-action>
					</v-list-item>

					<v-divider v-if="index < events.length - 1" :key="index"></v-divider>
				</template>
			</v-list-item-group>

			<v-pagination
				v-if="totalPages > 1"
				v-model="page"
				:length="totalPages"
			></v-pagination>
		</v-list>
    </div>

		<v-btn
			key="fa-plus"
			to="/private/create-events"
			color="green"
			fab
			dark
			fixed
			right
			bottom
		>
			<v-icon>fa-plus</v-icon>
		</v-btn>
	</div>
</template>

<script>
import TitlePage from '@/components/TitlePage'
import { getEvents } from '@/utils/privateEvents'
import EmptyState from '@/components/EmptyState.vue'

export default {
	name: 'PrivateEventsListView',
	components: { TitlePage, EmptyState },
	data: () => ({
		loading: true,
		error: false,
		events: [],
		page: 1,
		totalPages: 1,
	}),
	methods: {
		getApiInfo() {
			this.loading = true

			getEvents(this.page)
				.then((data) => {
					const [events, totalItems] = data
					this.events = events
					this.totalPages = Math.ceil(totalItems / 30)
				})
				.catch((err) => {
					console.log(err)
					this.error = true
				})
				.finally(() => {
					this.loading = false
				})
		},
	},
	created() {
		this.getApiInfo()
	},
	watch: {
		page() {
			this.getApiInfo()
		},
	},
}
</script>

<style scoped></style>
