import ApiService from '@/service/api.service'

export const getDoctors = (page) => {
	return ApiService.get(`/doctors?page=${page}`).then((res) => {
		const { data } = res
		return [data['hydra:member'], data['hydra:totalItems']]
	})
}

export const getDoctor = (id) => {
	return ApiService.get(`/doctors/${id}`).then((res) => {
		let { data } = res

		return data
	})
}

export const createDoctors = (data) => {
	return ApiService.post(`/doctors`, data).then((res) => {
		const { data } = res
		return data['hydra:member']
	})
}

export const updateDoctor = (id, data) => {
	return ApiService.put(`/doctors/${id}`, data).then((res) => {
		let { data } = res

		return data
	})
}

export const deleteDoctor = (id) => {
	return ApiService.delete(`/doctors/${id}`)
}
