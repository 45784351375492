<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#546ca4'"
			:title="'ESTAR AL DÍA'"
			:subtitle="'Información esencial'"
			:img="'informacion_blanco'"
		/>

		<section class="pa-4">
      <h1 class="page-title information-color">
				Pasaporte del superviviente o Surpass
			</h1>

			<img
				:src="require('../../assets/information/passport/pasaporte_1.jpg')"
				class="passport-section-image"
			/>

			<p>
				El pasaporte digital europeo para pacientes supervivientes de cáncer en
				la infancia o adolescencia tiene como objetivo mejorar su atención a
				largo plazo. El pasaporte del superviviente o SurPass actualmente está
				en fase de desarrollo e implementación.
			</p>

			<p>
				La iniciativa, enmarcada en el PanCareSurPass, un proyecto de 4 millones
				de euros, incluirá la descripción completa del tratamiento de cada
				superviviente, su historial y recomendaciones personalizadas, y se
				incorporará a la historia clínica electrónica. Se pretende aprovechar la
				transformación digital de la asistencia sanitaria para mejorar la
				atención a este colectivo en toda Europa.
			</p>

			<p>
				El pasaporte, que se emitirá en formato electrónico y en papel, será
				proporcionado a cada paciente al final del tratamiento, también recogerá
				una guía de orientación sobre el seguimiento a largo plazo y los
				posibles efectos tardíos derivados de la enfermedad o los tratamientos a
				los que fue sometido.
			</p>
		</section>

		<section class="pa-4">
      <h1 class="page-title information-color">
				¿Por qué es importante el pasaporte?
			</h1>

			<p>
				Actualmente hay 500.000 supervivientes de cáncer infantil y adolescente
				en Europa, una cifra que aumenta cada año. En nuestro país el 80% de los
				menores sobrevive a la enfermedad pero los tratamientos contra esta
				dolencia son duros y pueden causar efectos a largo plazo. Por este
				motivo, los supervivientes necesitan un control intenso de su salud a lo
				largo de la vida adulta.
			</p>

			<img
				:src="require('../../assets/information/passport/pasaporte_1.jpg')"
				class="passport-section-image"
			/>

			<p>
				Este seguimiento no está protocolizado y, en el momento del alta de las
				unidades de Oncología Pediátrica, solo una minoría de los supervivientes
				adultos de cáncer infantil reciben la atención adecuada.
			</p>
		</section>

		<section class="pa-4">
      <h1 class="page-title information-color">
				¿Cómo funcionará el pasaporte y en qué fase se encuentra?
			</h1>

			<p>
				Gracias a los algoritmos integrados, el pasaporte digital brindará
				recomendaciones personalizadas para la atención de seguimiento a largo
				plazo. Se está trabajando para que sea sencilla su implementación por
				parte del médico de atención primaria.
			</p>

			<img
				:src="require('../../assets/information/passport/pasaporte_3.jpg')"
				class="passport-section-image"
			/>

			<p>
				Parte del trabajo de desarrollo incluirá vincular el SurPass a los
				sistemas electrónicos de información de salud (registro médico del
				hospital y registros de salud nacionales) para mejorar la precisión de
				la información disponible y reducir el tiempo necesario para generar el
				SurPass para cada paciente.
			</p>

			<p>
				El proyecto PanCareSurPass arrancó en marzo de 2021 y está en fase de
				desarrollo. El SurPass se lanzará y se probará en una primera fase en
				Austria, Bélgica, Alemania, Italia, Lituania y España. En los próximos
				años se continuará trabajando para que sea una realidad.
			</p>
		</section>

		<section class="pa-4">
      <h1 class="page-title information-color">La Comunidad Valenciana presente</h1>

			<p>
				En la Comunidad Valenciana ha participado el Hospital La Fe en el
				desarrollo del proyecto. En concreto han colaborado investigadoras de la
				Unidad de Oncología Pediátrica del IIS La Fe y personal de la
				Subdirección de Sistemas de Información de las áreas de Seguridad y
				Aplicaciones Sanitarias del hospital.
			</p>
		</section>

		<section class="pa-4">
      <h1 class="page-title information-color">Más información</h1>

			<ul class="passport-section-list">
				<li>
					<a
						href="https://www.pancaresurpass.eu/"
						class="passport-section-link"
						target="_blank"
						>https://www.pancaresurpass.eu/
					</a>
				</li>
				<li>
					<a
						href="https://www.efe.com/efe/comunitat-valenciana/sociedad/la-fe-dara-un-pasaporte-digital-europeo-a-supervivientes-de-cancer-infantil/50000880-4580501 "
						class="passport-section-link"
						target="_blank"
						>https://www.efe.com/efe/comunitat-valenciana/sociedad/la-fe-dara-un-pasaporte-digital-europeo-a-supervivientes-de-cancer-infantil/50000880-4580501
					</a>
				</li>
			</ul>
		</section>
	</div>
</template>

<script>
import TitlePage from '@/components/TitlePage.vue'

export default {
	name: 'PassportView',
	components: { TitlePage },
}
</script>

<style scoped>

.passport-section-title {
	font-size: 22px;
	padding: 0 0 10px 0;
	color: #546ca4;
	font-weight: bold;
	font-size: 35px;
}

.passport-section-subtitle {
	font-size: 20px;
	padding: 10px 0 10px 0;
	font-weight: 500;
}

.passport-section-image {
	width: 100%;
	margin: 20px 0;
}

.passport-section-list {
	margin: 0 0 30px 0;
}

.passport-section-link {
	display: block;
	margin: 15px 0;
}

@media screen and (min-width: 1080px) {
	.passport-section {
		padding: 24px 0 0 0;
	}
}
</style>
