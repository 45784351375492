export class PageLink {
  constructor(id, title, link) {
    this.id = id;
    this.title = title;
    this.link = link;
  }

  static createFromAPI(request){
    var collection = []
    request.forEach( element => (
      collection.push(
        new PageLink(element.id, element.title, element.link)
      )
    ));

    return collection;
  }
}
