<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#D22630'"
			:title="'AREA PRIVADA'"
			:subtitle="'Herramientas para el día a día.'"
			:img="'login_blanco'"
		/>

		<div v-if="loading">
			<div class="text-center">
				<v-progress-circular
					indeterminate
					color="#D22630"
					class="mt-6"
				></v-progress-circular>
			</div>
		</div>

		<div v-else-if="!user">
			<EmptyState />
		</div>

		<v-form v-else ref="form" @submit.prevent="save">
			<v-container class="mb-13">
				<v-row>
					<h1 class="page-title private-color mt-6 ml-3">Pasaporte Superviviente</h1>
				</v-row>

				<v-radio-group
					v-model="user.tumorType"
					class="radio-inputs"
					:disabled="!this.editMode"
				>
					<v-radio
						label="Leucemia linfoblástica aguda (LLA)"
						:value="'lymphoblastic-leukemia'"
					></v-radio>
					<p>
						Es el tipo de leucemia más común en menores. Afecta a los
						linfocitos.
					</p>

					<v-radio
						label="Leucemia mieloide o granulocítica aguda "
						:value="'myeloid-leukemia'"
					></v-radio>
					<p>
						Generalmente se presenta en personas adultas, pero también puede
						darse en niñas/niños y adolescentes. Las células malignas se
						originan a partir de las células que dan origen a los neutrófilos.
					</p>

					<v-radio
						label="Leucemia mieloide crónica"
						:value="'chronic-myeloid-leukemia'"
					></v-radio>
					<p>
						Las leucemias crónicas son poco frecuentes en niños. Su
						característica es la presencia de un gran número de neutrófilos
						inmaduros.
					</p>

					<v-radio
						label="Linfoma de Hodgkin"
						:value="'hodgkin-lymphoma'"
					></v-radio>
					<p>
						Los linfomas son cánceres que se desarrollan a partir del sistema
						linfático, el cual forma parte del sistema inmunológico. El Linfoma
						de Hodgkin generalmente afecta a los ganglios linfáticos
						periféricos, en personas de 10 a 30 años.
					</p>

					<v-radio
						label="Linfoma no Hodgkin"
						:value="'hodgkin-no-lymphoma'"
					></v-radio>
					<p>
						Los linfomas son cánceres que se desarrollan a partir del sistema
						linfático, el cual forma parte del sistema inmunológico. El Linfoma
						no Hodgkin es más frecuente en los niños. También puede aparecer en
						el hígado, bazo, sistema nervioso, médula ósea y ganglios
						linfáticos.
					</p>

					<v-radio label="Neuroblastoma" :value="'neuroblastoma'"></v-radio>
					<p>
						Es un tumor derivado de las células nerviosas adrenérgicas. La
						localización más frecuente es el abdomen. Es un tumor que sólo se
						desarrolla en niños, generalmente por debajo de los cinco años.
					</p>

					<v-radio label="Tumor de Wilms" :value="'wilms-tumor'"></v-radio>
					<p>
						Es un tumor maligno que afecta a las células de los riñones. Ocurre
						generalmente en niños antes de los diez años y en algunos casos
						puede ser bilateral.
					</p>

					<v-radio label="Retinoblastoma" :value="'retinoblastoma'"></v-radio>
					<p>
						Es un tumor maligno intraocular (retina). En casi la mitad de los
						pacientes es bilateral. Lo más frecuente es que este tumor se
						desarrolle en niños menores de 5 años, aunque puede aparecer a
						cualquier edad.
					</p>

					<v-radio
						label="Rabdomiosarcoma"
						:value="'rhabdomyosarcoma'"
					></v-radio>
					<p>
						Es un tipo de tumor de partes blandas que afecta fundamentalmente a
						las células de los músculos. Aunque puede ocurrir en cualquier área
						muscular, las localizaciones más frecuentes son cabeza y cuello,
						pelvis y extremidades.
					</p>

					<v-radio label="Osteosarcoma" :value="'osteosarcoma'"></v-radio>
					<p>
						Es la forma de cáncer de hueso más frecuente en los niños.
						Generalmente aparecen en los huesos largos del brazo (húmero) y de
						la pierna (fémur y tibia). Ocurre entre los 10 y 25 años.
					</p>

					<v-radio label="Sarcoma de Ewing" :value="'ewing-sarcoma'"></v-radio>
					<p>
						Es otra forma de cáncer de hueso que afecta a huesos largos y
						también planos. Al igual que el osteosarcoma, generalmente se
						presenta entre los 10 y los 25 años.
					</p>

					<v-radio
						label="Otro tipo de cáncer o tumor"
						:value="'other'"
					></v-radio>
					<v-textarea
						v-if="user.tumorType == 'other'"
						name="questionFormMessage"
						label="Mensaje"
						v-model="user.otherTumorType"
						class="mb-10 mt-3"
						outlined
					></v-textarea>
				</v-radio-group>

				<v-row class="mt-10">
					<v-col>
						<v-text-field
							v-model="user.year"
							prepend-icon="fa-calendar"
							label="Año"
							:readonly="!this.editMode"
							:rules="numberRules"
						></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" md="6">
						<v-textarea
							v-model="user.relapses"
							label="Recaidas"
							:readonly="!this.editMode"
						></v-textarea>
					</v-col>

					<v-col cols="12" md="6">
						<v-textarea
							label="Antecedentes"
							v-model="user.background"
							:readonly="!this.editMode"
						></v-textarea>
					</v-col>
				</v-row>
			</v-container>
		</v-form>

		<v-btn
			v-if="editMode"
			key="fa-save"
			color="green"
			fab
			dark
			fixed
			right
			style="bottom: 80px"
			:loading="loadingBtn"
			v-on:click="save"
		>
			<v-icon>fa-save</v-icon>
		</v-btn>

		<v-btn
			v-if="!editMode"
			key="fa-save"
			color="blue darken-2"
			fab
			dark
			fixed
			right
			style="bottom: 80px"
			v-on:click="edit"
		>
			<v-icon>fa-pen</v-icon>
		</v-btn>

		<v-btn
			v-if="this.editMode"
			color="red"
			fab
			dark
			fixed
			right
			bottom
			style="right: 100px; bottom: 80px"
			v-on:click="cancel"
		>
			<v-icon>fa-ban</v-icon>
		</v-btn>

    <profile-tab-menu>
    </profile-tab-menu>

		<v-snackbar v-model="message.status">
			{{ message.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="message.status = false"
				>
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TitlePage from '@/components/TitlePage'
import EmptyState from '@/components/EmptyState.vue'
import { getUser, updateUser } from '@/utils/user'
import ProfileTabMenu from "@/components/ProfileTabMenu";

export default {
	name: 'ProfileView',
	components: {ProfileTabMenu, TitlePage, EmptyState },
	data() {
		return {
			user: null,
			lastInfo: {},
			editMode: false,
			loadingBtn: false,
			loading: true,
			numberRules: [(v) => !isNaN(v) || 'El numero tiene que ser válido'],
			message: {
				status: false,
				message: '',
			},
		}
	},
	methods: {
		...mapActions('auth', ['Logout']),
		...mapGetters('auth', ['userId']),
		save() {
			const form = this.$refs.form
			const validate = form.validate()

			if (validate) {
				const user = {
					year: parseInt(this.user.year),
					tumorType: this.user.tumorType || '',
					relapses: this.user.relapses || '',
					background: this.user.background || '',
					otherTumorType: this.user.otherTumorType || '',
				}

				this.loadingBtn = true

				updateUser(user, this.userId())
					.then(() => {
						this.message.status = true
						this.message.message = 'Se ha editado correctamente'
					})
					.catch((err) => {
						console.log(err)
						this.user = { ...this.lastInfo }
						this.message.status = true
						this.message.message = 'Ha habido un error'
					})
					.finally(() => {
						this.loadingBtn = false
						this.editMode = false
					})
			}
		},
		edit() {
			this.lastInfo = { ...this.user }
			this.editMode = true
		},
		cancel() {
			this.user = { ...this.lastInfo }
			this.editMode = false
		},
	},
	created() {
		getUser(this.userId())
			.then((user) => {
				this.user = user
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				this.loading = false
			})
	},
}
</script>

<style scoped>
.radio-inputs {
	padding: 24px;
	margin: 30px 0;
}

.radio-inputs p {
	color: #444;
	margin: 0 0 30px 25px;
}

@media screen and (min-width: 1080px) {
	.radio-inputs {
		padding: 0;
	}
}
</style>
