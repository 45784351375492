import axios from 'axios'
import { API_URL } from './globals'

export const getHospitals = (page, province, city, name) => {
	let query = `page=${page}`

	if (province.length > 0) query += `&province=${province}`
	if (city.length > 0) query += `&city=${city}`
	if (name.length > 0) query += `&name=${name}`

	return axios.get(`${API_URL}/hospitals?${query}`).then((res) => {
		const { data } = res
		const hospitals = data['hydra:member']

		return {
			data,
			hospitals,
		}
	})
}

export const getProvinces = () => {
	return axios.get(`${API_URL}/custom/hospitals/provinces`).then((res) => {
		const { data } = res
		const { provinces } = data

		return provinces
	})
}

export const getCities = (province) => {
	return axios
		.get(`${API_URL}/custom/hospitals/cities?province=${province}`)
		.then((res) => {
			return res.data.cities
		})
		.catch((err) => console.log(err))
}
