<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#546ca4'"
			:title="'ESTAR AL DÍA'"
			:subtitle="'Información esencial'"
			:img="'informacion_blanco'"
		/>

		<div v-if="loading">
			<div class="text-center">
				<v-progress-circular
					indeterminate
					color="#546ca4"
					class="mt-6"
				></v-progress-circular>
			</div>
		</div>

		<div v-else-if="events.length <= 0 || error">
			<EmptyState />
		</div>

		<v-list two-line v-else class="pt-0 mt-0">
			<v-list-item-group>
				<template v-for="(event, index) in events">
					<EventItem
						v-for="event in events"
						:key="event.id"
						:name="event.name"
						:date="event.getDate()"
						:id="event.id"
					/>

					<v-divider v-if="index < event.length - 1" :key="index"></v-divider>
				</template>
			</v-list-item-group>

			<v-pagination
				v-if="totalPages > 1"
				v-model="page"
				:length="totalPages"
				class="mt-6"
			></v-pagination>
		</v-list>
	</div>
</template>

<script>
import axios from 'axios'
import TitlePage from '@/components/TitlePage.vue'
import { Event } from '@/model/Event'
import { API_URL } from '@/utils/globals'
import EventItem from '@/components/EventItem.vue'
import EmptyState from '@/components/EmptyState.vue'

export default {
	name: 'ListEventsView',
	components: {
		TitlePage,
		EventItem,
		EmptyState,
	},
	data() {
		return {
			events: [],
			loading: true,
			error: false,
			page: 1,
			totalPages: 1,
		}
	},
	created() {
		axios
			.get(`${API_URL}/global_events`, {
        params: {
          "date[after]": ( new Date() ).setHours(0, 0, 0, 0)
        }
      })
			.then((res) => {
				const { data } = res
				this.totalPages = Math.ceil(data['hydra:totalItems'] / 30)

				this.events = Event.createCollectionFromAPI(data['hydra:member'])
				this.loading = false
			})
			.catch((err) => {
				console.log(err)
				this.error = true
			})
			.finally(() => {
				this.loading = false
			})
	},
	watch: {
		page() {
			this.loading = true

			axios
				.get(`${API_URL}/global_events?page=${this.page}`)
				.then((res) => {
					const { data } = res
					this.totalPages = Math.ceil(data['hydra:totalItems'] / 30)

					this.events = Event.createCollectionFromAPI(data['hydra:member'])
					this.loading = false
				})
				.catch((err) => {
					console.log(err)
					this.error = true
				})
				.finally(() => {
					this.loading = false
				})
		},
	},
}
</script>

<style scoped></style>
