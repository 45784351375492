<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#D22630'"
			:title="'AREA PRIVADA'"
			:subtitle="'Herramientas para el día a día.'"
			:img="'login_blanco'"
		/>

    <h1 class="page-title private-color px-4 pt-4">Añadir documento</h1>
		<v-form ref="form" @submit.prevent="save">
			<v-container>
				<v-row>
          <v-col cols="12" md="6">
              <v-text-field
                v-model="name"
                prepend-icon="fa-user"
                label="Nombre*"
                :rules="noEmptyRule"
              ></v-text-field>
          </v-col>
				</v-row>

				<v-row>
          <v-col cols="12" md="6">
            <v-file-input
              label="Archivo*"
              outlined
              :rules="fileRule"
              v-model="file"
              accept="image/*, application/pdf"
            ></v-file-input>
          </v-col>
				</v-row>
			</v-container>
		</v-form>

		<v-row>
			<v-btn
				color="green"
				fab
				dark
				fixed
				right
				bottom
				@click="save"
				:loading="loading"
			>
				<v-icon>fa-save</v-icon>
			</v-btn>
			<v-btn
				color="red"
				fab
				dark
				fixed
				right
				bottom
				style="right: 100px"
				to="/private/documents"
			>
				<v-icon>fa-ban</v-icon>
			</v-btn>
		</v-row>
	</div>
</template>

<script>
import TitlePage from '@/components/TitlePage'
import { addDocuments } from '@/utils/documents'

export default {
	name: 'PrivateDocumentsCreateView',
	components: { TitlePage },
	data: () => ({
		noEmptyRule: [(v) => !!v || 'Campo Requerido'],
		name: '',
		file: undefined,
		modal: false,
		loading: false,
		fileRule: [
			(v) => !!v || 'Campo Requerido'
		],
	}),
	methods: {
		save() {
			const form = this.$refs.form
			const validate = form.validate()

			if (validate) {
				const formData = new FormData()

				formData.append('name', this.name)
				formData.append('fileName', this.file.name)
				formData.append('file', this.file)

				this.loading = true

				addDocuments(formData)
					.then(() => {
						this.$router.push('/private/documents')
					})
					.catch((err) => {
						console.log(err)
					})
					.finally(() => {
						this.loading = false
					})
			}
		},
	},
}
</script>

<style scoped></style>
