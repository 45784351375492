import ApiService from '@/service/api.service'

export const getDocuments = (page) => {
	return ApiService.get(`/reports?page=${page}`).then((res) => {
		const { data } = res
		return [data['hydra:member'], data['hydra:totalItems']]
	})
}

export const getDocument = (id) => {
	return ApiService.get(`/reports/${id}`).then((res) => {
		let { data } = res

		return data
	})
}

export const addDocuments = (data) => {
	return ApiService.post(`/reports`, data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

export const updateDocument = (data, id) => {
	return ApiService.put(`/reports/${id}`, data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

export const deleteDocument = (id) => {
	return ApiService.delete(`/reports/${id}`)
}
