<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#D22630'"
			:title="'AREA PRIVADA'"
			:subtitle="'Herramientas para el día a día.'"
			:img="'login_blanco'"
		/>

		<div v-if="loading">
			<div class="text-center">
				<v-progress-circular
					indeterminate
					color="#D22630"
					class="mt-6"
				></v-progress-circular>
			</div>
		</div>

		<div v-else-if="!user">
			<EmptyState />
		</div>

		<div class="mb-15" v-else>
			<v-form ref="form">
				<v-container>
					<v-row>
						<h1 class="mt-6 ml-3">Perfil</h1>
					</v-row>
					<v-row class="mt-10">
						<v-col>
							<v-text-field
								v-model="user.name"
								prepend-icon="fa-user"
								label="Nombre*"
								:readonly="!this.editMode"
								:rules="noEmptyRule"
							></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" md="6">
							<v-text-field
								v-model="user.telephone"
								prepend-icon="fa-phone"
								label="Teléfono"
								:readonly="!this.editMode"
							></v-text-field>
						</v-col>

						<v-col cols="12" md="6">
							<v-text-field
								name="questionFormEmail"
								label="Email"
								prepend-icon="fa-at"
								v-model="user.email"
								readonly
							></v-text-field>
						</v-col>
					</v-row>
				</v-container>
			</v-form>

			<v-form ref="formPassword" @submit.prevent="save" class="mt-10">
				<v-container>
					<v-row>
						<h3 class="mt-2 ml-3">Cambiar contraseña</h3>
					</v-row>
					<v-row>
						<v-col cols="12" md="6">
							<v-text-field
								v-model="newPassword"
								prepend-icon="fa-key"
								label="Contraseña"
								type="password"
								:rules="noEmptyRule"
							></v-text-field>
						</v-col>

						<v-col cols="12" md="6">
							<v-text-field
								label="Confirmar contraseña"
								prepend-icon="fa-key"
								v-model="confirmNewPassword"
								type="password"
								:rules="noEmptyRule"
							></v-text-field>
						</v-col>
					</v-row>

					<v-btn @click="changePassword" :loading="loadingPassword"
						>Cambiar contraseña</v-btn
					>
				</v-container>
			</v-form>

			<v-btn
				:disabled="this.editMode"
				@click="logout"
				class="mt-12 ml-3 mb-6"
				color="#f00"
				style="color: #fff"
				left
			>
				Desonectar
			</v-btn>
		</div>

		<v-btn
			v-if="editMode"
			key="fa-save"
			color="green"
			fab
			dark
			fixed
			right
			style="bottom: 80px"
			:loading="loadingBtn"
			v-on:click="save"
		>
			<v-icon>fa-save</v-icon>
		</v-btn>

		<v-btn
			v-if="!editMode"
			key="fa-save"
			color="blue darken-2"
			fab
			dark
			fixed
			right
			style="bottom: 80px"
			v-on:click="edit"
		>
			<v-icon>fa-pen</v-icon>
		</v-btn>

		<v-btn
			v-if="this.editMode"
			color="red"
			fab
			dark
			fixed
			right
			bottom
			style="right: 100px; bottom: 80px"
			v-on:click="cancel"
		>
			<v-icon>fa-ban</v-icon>
		</v-btn>

    <profile-tab-menu></profile-tab-menu>

		<v-snackbar v-model="message.status">
			{{ message.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="message.status = false"
				>
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TitlePage from '@/components/TitlePage'
import { getUser, updateUser } from '@/utils/user'
import EmptyState from '@/components/EmptyState.vue'
import ProfileTabMenu from "@/components/ProfileTabMenu";

export default {
	name: 'ProfileView',
	components: {ProfileTabMenu, TitlePage, EmptyState },
	data() {
		return {
			user: null,
			lastInfo: {},
			editMode: false,
			noEmptyRule: [(v) => !!v || 'Campo Requerido'],
			emailRules: [
				(v) => !!v || 'E-mail requerido',
				(v) => /.+@.+\..+/.test(v) || 'E-mail tiene que ser valido',
			],
			phoneRules: [
				(v) => !isNaN(v) || 'El numero tiene que ser valido',
				(v) => v.length <= 9 || 'El numero tiene que ser valido',
			],
			loadingBtn: false,
			loading: true,
			loadingPassword: false,
			newPassword: '',
			confirmNewPassword: '',
			message: {
				status: false,
				message: '',
			},
		}
	},
	methods: {
		...mapActions('auth', ['Logout']),
		...mapGetters('auth', ['userId']),
		logout() {
			this.Logout()
			this.$router.push('/login')
		},
		edit() {
			this.lastInfo = { ...this.user }
			this.editMode = true
		},
		save() {
			const form = this.$refs.form
			const validate = form.validate()

			if (validate) {
				const user = {
					name: this.user.name,
					telephone: this.user.telephone || '',
				}

				this.loadingBtn = true

				updateUser(user, this.userId())
					.then(() => {
						this.message.status = true
						this.message.message = 'Se ha editado correctamente'
					})
					.catch((err) => {
						console.log(err)
						this.user = { ...this.lastInfo }
						this.message.status = true
						this.message.message = 'Ha habido un error'
					})
					.finally(() => {
						this.loadingBtn = false
						this.editMode = false
					})
			}
		},
		cancel() {
			this.user = { ...this.lastInfo }
			this.editMode = false
		},
		changePassword() {
			const form = this.$refs.formPassword
			const validate = form.validate()

			if (validate) {
				if (this.newPassword == this.confirmNewPassword) {
					this.loadingPassword = true

					updateUser({ plainPassword: this.newPassword }, this.userId())
						.then(() => {
							this.message.status = true
							this.message.message = 'Contraseña cambiada'
							form.reset()
						})
						.catch((err) => {
							console.log(err)
							this.message.status = true
							this.message.message = 'Ha habido un error'
						})
						.finally(() => {
							this.loadingPassword = false
						})
				} else {
					this.message.status = true
					this.message.message = 'Las contraseñas no coincide'
				}
			}
		},
	},
	created() {
		getUser(this.userId())
			.then((user) => {
				this.user = user
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				this.loading = false
			})
	},
}
</script>

<style scoped></style>
