import { render, staticRenderFns } from "./ListNewsView.vue?vue&type=template&id=2eb5b8cb&scoped=true&"
import script from "./ListNewsView.vue?vue&type=script&lang=js&"
export * from "./ListNewsView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2eb5b8cb",
  null
  
)

export default component.exports