import { render, staticRenderFns } from "./PrivateAreaView.vue?vue&type=template&id=58c54b33&scoped=true&"
import script from "./PrivateAreaView.vue?vue&type=script&lang=js&"
export * from "./PrivateAreaView.vue?vue&type=script&lang=js&"
import style0 from "./PrivateAreaView.vue?vue&type=style&index=0&id=58c54b33&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58c54b33",
  null
  
)

export default component.exports