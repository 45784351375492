<template>
	<v-list-item :to="`/information/events/${id}`">
		<v-list-item-avatar>
			<v-icon class="grey lighten-1" dark> fa-calendar </v-icon>
		</v-list-item-avatar>

		<v-list-item-content>
			<v-list-item-title v-text="date"></v-list-item-title>

			<v-list-item-subtitle v-text="name"></v-list-item-subtitle>
		</v-list-item-content>

		<v-list-item-action> </v-list-item-action>
	</v-list-item>
</template>

<script>
export default {
	name: 'EventItem',
	props: {
		date: String,
		name: String,
		id: String,
	},
	data() {
		return {
			vertical: false,
		}
	},
}
</script>

<style scoped></style>
