<template>
	<v-col cols="12" md="6"  class="pa-2">
		<v-card
			:to="`/information/news/${post.id}`"
			hover
			height="100%"
			class="mx-auto"
			max-width="600"
		>
			<v-img
				class="white--text align-end"
				height="200px"
				:src="post.getImgUrl()"
			>
			</v-img>

			<v-card-subtitle class="pb-0">
				{{ post.getDate() }}
			</v-card-subtitle>

			<v-card-text class="text--primary">
				<h3>{{ post.title }}</h3>
			</v-card-text>
		</v-card>
	</v-col>
</template>

<script>
export default {
	name: 'NewsItem',
	props: {
		post: Object,
	},
}
</script>

<style scoped>
.news-card {
	margin: 0 0 25px 0;
}

.news-title {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
