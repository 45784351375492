export class Post {
  constructor(id, title, content, date, img) {
    this.id = id;
    this.title = title;
    this.content = content;
    this.date = date;
    this.img = img;
  }

  static createCollectionFromAPI(request){
    var collection = []
    request.forEach( element => (
      collection.push(
        new Post(element.id, element.title, element.content, element.createdAt, element.headerImage)
      )
    ));
    return collection;
  }

  getDate(){
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    let date = new Date(this.date)
    return date.toLocaleDateString('es-ES', options)
  }

  getImgUrl(){
    return 'https://private.supervivientescancerinfantil.es/uploads/posts/' + this.img;
  }
}
