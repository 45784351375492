<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#546ca4'"
			:title="'ESTAR AL DÍA'"
			:subtitle="'Información esencial'"
			:img="'informacion_blanco'"
		/>

		<div v-if="loading">
			<div class="text-center">
				<v-progress-circular
					indeterminate
					color="#546ca4"
					class="mt-6"
				></v-progress-circular>
			</div>
		</div>

		<div v-else-if="posts.length <= 0 || error">
			<EmptyState />
		</div>

		<v-row class="mt-2" no-gutters v-else>
			<NewsItem v-for="post in posts" :key="post.id" :post="post" />
		</v-row>
		<v-pagination
			v-if="totalPages > 1"
			v-model="page"
			:length="totalPages"
			class="mt-6"
		></v-pagination>
	</div>
</template>

<script>
import NewsItem from '@/components/NewsItem.vue'
import { getPosts } from '@/utils/getPosts.js'
import TitlePage from '@/components/TitlePage.vue'
import EmptyState from '@/components/EmptyState.vue'

export default {
	name: 'ListNewsView',
	data() {
		return {
			posts: [],
			loading: true,
			error: false,
			page: 1,
			totalPages: 1,
		}
	},
	components: {
		NewsItem,
		TitlePage,
		EmptyState,
	},
	async created() {
		const [result, totalPages] = await getPosts(this.page)

		this.totalPages = totalPages

		if (result != 'Error') this.posts = result
		else this.error = true
		this.loading = false
	},
}
</script>

<style scoped></style>
