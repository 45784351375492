<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#D22630'"
			:title="'AREA PRIVADA'"
			:subtitle="'Herramientas para el día a día.'"
			:img="'login_blanco'"
		/>

		<div v-if="loading">
			<div class="text-center">
				<v-progress-circular
					indeterminate
					color="#D22630"
					class="mt-6"
				></v-progress-circular>
			</div>
		</div>

		<div v-else-if="!document || error.status">
			<EmptyState />
		</div>

    <div v-else>
      <h1 class="page-title private-color px-4 pt-4">Documento</h1>
      <v-form ref="form" @submit.prevent="save">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="document.name"
                prepend-icon="fa-user"
                label="Nombre*"
                :rules="noEmptyRule"
                :readonly="!this.editMode"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mt-5 text-center ma-auto">
            <v-btn
                :href="`https://private.supervivientescancerinfantil.es/uploads/reports/${document.fileName}`"
                target="_blank"
            >
              <v-icon style="font-size: 15px" class="mr-2">fa-file</v-icon>
              Ver Documento
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </div>

		<v-speed-dial
			direction="top"
			transition="slide-y-reverse-transition"
			v-model="fab"
			dark
			fixed
			right
			bottom
			v-if="!this.editMode"
		>
			<template v-slot:activator>
				<v-btn v-model="fab" color="blue darken-2" dark fab>
					<v-icon v-if="fab"> fa-times </v-icon>
					<v-icon v-else> fa-pen </v-icon>
				</v-btn>
			</template>
			<v-btn fab dark small color="blue darken-2" v-on:click="edit">
				<v-icon>fa-pen</v-icon>
			</v-btn>
			<v-btn fab dark small color="red" @click="overlay = true">
				<v-icon>fa-trash</v-icon>
			</v-btn>
		</v-speed-dial>

    <v-btn
        v-if="! this.editMode"
        color="secondary"
        fab
        dark
        fixed
        right
        bottom
        style="right: 100px"
        to="/private/documents"
    >
      <v-icon>fa-arrow-left</v-icon>
    </v-btn>

		<v-btn
			v-if="this.editMode"
			key="fa-save"
			color="green"
			fab
			dark
			fixed
			right
			bottom
			:loading="loadingBtn"
			v-on:click="save"
		>
			<v-icon>fa-save</v-icon>
		</v-btn>

		<v-btn
			v-if="this.editMode"
			color="red"
			fab
			dark
			fixed
			right
			bottom
			style="right: 100px"
			v-on:click="cancel"
		>
			<v-icon>fa-ban</v-icon>
		</v-btn>

		<v-overlay :value="overlay">
			<v-card elevation="1" light flat width="400px">
				<v-card-text style="color: #000">
					¿Quieres eliminar este documento?
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn outlined rounded text @click="overlay = false">
						<v-icon class="mr-2" small>fa-ban</v-icon>
						CANCELAR
					</v-btn>
					<v-btn
						outlined
						rounded
						text
						style="background: #f00"
						color="#fff"
						:loading="loadingBtn"
						@click="deleteDocument"
					>
						<v-icon class="mr-2" small>fa-trash</v-icon>
						ELIMINAR
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-overlay>

		<v-snackbar v-model="message.status">
			{{ message.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="message.status = false"
				>
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>

		<v-snackbar v-model="error.status">
			{{ error.message }}

			<template v-slot:action="{ attrs }">
				<v-btn color="white" text v-bind="attrs" @click="error.status = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
import TitlePage from '@/components/TitlePage'
import EmptyState from '@/components/EmptyState.vue'
import { getDocument, updateDocument, deleteDocument } from '@/utils/documents'

export default {
	name: 'PrivateDocumentsDetailView',
	components: { TitlePage, EmptyState },
	data: () => ({
		noEmptyRule: [(v) => !!v || 'Campo Requerido'],
		modal: false,
		document: null,
		lastInfo: {},
		file: undefined,
		fab: false,
		editMode: false,
		loading: true,
		message: {
			status: false,
			message: '',
		},
		error: {
			status: false,
			message: '',
		},
		loadingBtn: false,
		overlay: false,
	}),
	methods: {
		edit() {
			this.lastInfo = { ...this.document }
			this.editMode = true
		},
		save() {
			const form = this.$refs.form
			const validate = form.validate()

			if (validate) {
				const { id } = this.$route.params
				this.loadingBtn = true

				const formData = new FormData()

				formData.append('name', this.document.name)
				if (this.file.name != this.document.fileName) {
					console.log('Cambio')
					formData.append('fileName', this.file.name)
					formData.append('file', this.file)
				}

				updateDocument(formData, id)
					.then((res) => {
            console.log(res);
						this.document = res.data
						this.message.status = true
						this.message.message = 'Se ha editado correctamente'
					})
					.catch((err) => {
						console.log(err)
						this.error.status = true
						this.error.message = 'Se ha producido un error'
						this.document = { ...this.lastInfo }
					})
					.finally(() => {
						this.loadingBtn = false
						this.editMode = false
					})
			}
		},
		deleteDocument() {
			const { id } = this.$route.params
			this.loadingBtn = true

			deleteDocument(id)
				.then(() => {
					this.$router.push('/private/documents')
				})
				.catch((err) => {
					console.log(err)
					this.error.status = true
					this.error.message = 'Se ha producido un error'
				})
				.finally(() => {
					this.loadingBtn = false
				})
		},
		cancel() {
			this.document = { ...this.lastInfo }
			this.file = new File([], this.lastInfo.fileName)
			this.editMode = false
		},
	},
	created() {
		const { id } = this.$route.params

		getDocument(id)
			.then((document) => {
				this.file = new File([], document.fileName)
				this.document = document
			})
			.catch((err) => {
				console.log(err)
				this.error = true
			})
			.finally(() => {
				this.loading = false
			})
	},
}
</script>

<style scoped></style>
