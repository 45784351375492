<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#546ca4'"
			:title="'ESTAR AL DÍA'"
			:subtitle="'Información esencial'"
			:img="'informacion_blanco'"
		/>
		<v-row class="mt-2" no-gutters>
      <div class="col-md-6 col-sm-6 col-xs-12">
        <v-card class="pa-2"  elevation="0">
          <v-img
              :src="require('../assets/information/para-quien.png')"
              class="white--text align-center"
              height="200px"
          >
            <div class="text-center">
              <v-btn
                  to="/information/util"
                  class="white--text px-16 py-8 salud-btn"
                  outlined
              >
                Para quien es útil<br />esta APP
              </v-btn>
            </div>
          </v-img>
        </v-card>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-12">
        <v-card class="pa-2" elevation="0">
          <v-img
              :src="require('../assets/information/noticias.jpg')"
              class="white--text align-center"
              height="200px"
          >
            <div class="text-center">
              <v-btn
                  to="/information/news"
                  class="white--text px-16 py-8 salud-btn"
                  outlined
              >
                Noticias
              </v-btn>
            </div>
          </v-img>
        </v-card>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-12">
        <v-card class="pa-2" elevation="0">
          <v-img
              :src="require('../assets/information/testimonios.jpg')"
              class="white--text align-center"
              height="200px"
          >
            <div class="text-center">
              <v-btn
                  to="/information/testimonies"
                  class="white--text px-16 py-8 salud-btn"
                  outlined
              >
                Testimonios
              </v-btn>
            </div>
          </v-img>
        </v-card>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-12">
        <v-card class="pa-2" elevation="0">
          <v-img
              :src="require('../assets/information/pasaporte_superviviente.jpg')"
              class="white--text align-center"
              height="200px"
          >
            <div class="text-center">
              <v-btn
                  to="/information/passport"
                  class="white--text px-16 py-8 salud-btn"
                  outlined
              >
                El pasaporte <br />
                del superviviente
              </v-btn>
            </div>
          </v-img>
        </v-card>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-12">
        <v-card class="pa-2" elevation="0">
          <v-img
              :src="require('../assets/information/agenda.jpg')"
              class="white--text align-center"
              height="200px"
          >
            <div class="text-center">
              <v-btn
                  to="/information/events"
                  class="white--text px-16 py-8 salud-btn"
                  outlined
              >
                Agenda
              </v-btn>
            </div>
          </v-img>
        </v-card>
      </div>
			<div class="col-md-6 col-sm-6 col-xs-12">
				<v-card class="pa-2" elevation="0">
					<v-img
						:src="require('../assets/information/quienes_somos.jpg')"
						class="white--text align-center"
						height="200px"
					>
						<div class="text-center">
              <v-btn
                  to="/information/about"
                  class="white--text px-16 py-8 salud-btn"
                  outlined
              >
                Información sobre<br />ASPANION
              </v-btn>
						</div>
					</v-img>
				</v-card>
			</div>
			<div class="col-md-6 col-sm-6 col-xs-12">
        <v-card class="pa-2" elevation="0">
					<v-img
						:src="require('../assets/information/colabora-aspanion.png')"
						class="white--text align-center"
						height="200px"
					>
						<div class="text-center">
              <v-btn
                  to="/information/collaborate"
                  class="white--text px-16 py-8 salud-btn"
                  outlined
              >
                Colabora con<br />ASPANION
              </v-btn>
						</div>
					</v-img>
				</v-card>
			</div>
			<div class="col-md-6 col-sm-6 col-xs-12">
        <v-card class="pa-2" elevation="0">
					<v-img
						:src="require('../assets/information/hospitales.jpg')"
						class="white--text align-center"
						height="200px"
					>
						<div class="text-center">
              <v-btn
                  to="/information/hospitals"
                  class="white--text px-16 py-8 salud-btn"
                  outlined
              >
                Hospitales
              </v-btn>
						</div>
					</v-img>
				</v-card>
			</div>
      <div class="col-md-6 col-sm-6 col-xs-12">
        <v-card class="pa-2" elevation="0">
          <v-img
              :src="require('../assets/information/proyecto_hecho_realidad.jpg')"
              class="white--text align-center"
              height="200px"
          >
            <div class="text-center">
              <v-btn
                  to="/information/project"
                  class="white--text px-16 py-8 salud-btn"
                  outlined
              >
                Un proyecto <br />hecho realidad
              </v-btn>
            </div>
          </v-img>
        </v-card>
      </div>
		</v-row>

	</div>
</template>

<script>
import TitlePage from '@/components/TitlePage'

export default {
	components: { TitlePage },
	name: 'InformationView.vue',
}
</script>

<style scoped>
.salud-btn {
	background-color: rgb(0, 0, 0, 0.4);
}
</style>
