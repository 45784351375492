<template>
	<v-main>
		<v-container fluid fill-height>
			<v-layout align-center justify-center>
				<v-flex xs12 sm6 md6>
					<v-card class="elevation-1">
						<v-toolbar dark color="#434544">
							<v-toolbar-title>Area Privada</v-toolbar-title>
						</v-toolbar>
						<v-card-text>
							<v-form ref="form" @submit.prevent="login" id="login-form">
								<v-text-field
									v-model="user.email"
									prepend-icon="fa-user"
									name="email"
									label="Email"
									type="text"
									:rules="noEmptyRule"
								></v-text-field>
								<v-text-field
									v-model="user.password"
									id="password"
									prepend-icon="fa-lock"
									name="password"
									label="Contraseña"
									type="password"
									:rules="noEmptyRule"
								></v-text-field>
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-btn
								elevation="0"
								plain
								type="submit"
								form="login-form"
								to="register"
							>
								Crear Cuenta
							</v-btn>
							<v-spacer></v-spacer>
							<v-btn
								:loading="loading"
								:disabled="loading"
								type="submit"
								form="login-form"
							>
								Entrar
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-snackbar v-model="error.status">
			{{ this.error.message }}

			<template v-slot:action="{ attrs }">
				<v-btn color="white" text v-bind="attrs" @click="error.status = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
	</v-main>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'LoginView',
	data: () => ({
		user: {
			email: '',
			password: '',
		},
		loading: false,
		error: {
			status: false,
			message: '',
		},
		noEmptyRule: [(v) => !!v || 'Campo Requerido'],
	}),
	methods: {
		...mapActions('auth', ['LogIn']),
		async login() {
			let isFormValid = this.$refs.form.validate()
			if (isFormValid) {
				this.loading = true
				await this.LogIn(this.user)
					.then(() => {
						this.$router.push('/private')
					})
					.catch((error) => {
						this.error.status = true
						this.error.message = error.message
					})
					.finally(() => {
						this.loading = false
					})
			}
		},
	},
}
</script>

<style scoped></style>
