<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#D22630'"
			:title="'AREA PRIVADA'"
			:subtitle="'Herramientas para el día a día.'"
			:img="'login_blanco'"
		/>

    <h1 class="page-title private-color px-4 pt-4">Añadir especialista</h1>
		<v-form ref="form" @submit.prevent="save">
			<v-container>
				<v-row>
					<v-col cols="12" md="6">
						<v-text-field
							v-model="name"
							prepend-icon="fa-user"
							label="Nombre*"
							:rules="noEmptyRule"
						></v-text-field>
					</v-col>

					<v-col cols="12" md="6">
						<v-text-field
							v-model="telephone"
							prepend-icon="fa-phone"
							label="Teléfono"
						></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" md="6">
						<v-text-field
							v-model="center"
							prepend-icon="fa-hospital"
							label="Centro"
						></v-text-field>
					</v-col>

					<v-col cols="12" md="6">
						<v-text-field
							v-model="specialism"
							prepend-icon="fa-id-card"
							label="Especialidad"
						></v-text-field>
					</v-col>

					<v-col cols="12" md="6">
						<v-text-field
							v-model="area"
							prepend-icon="fa-adjust"
							label="Area"
						></v-text-field>
					</v-col>
				</v-row>

				<v-row>
          <v-col cols="12" md="6">
            <v-textarea
              name="input-7-1"
              label="Observaciones"
              v-model="observations"
            ></v-textarea>
          </v-col>
				</v-row>
			</v-container>
		</v-form>

		<v-row>
			<v-btn
				color="green"
				fab
				dark
				fixed
				right
				bottom
				@click="save"
				:loading="loading"
			>
				<v-icon>fa-save</v-icon>
			</v-btn>
			<v-btn
				color="red"
				fab
				dark
				fixed
				right
				bottom
				style="right: 100px"
				to="/private/doctors"
			>
				<v-icon>fa-ban</v-icon>
			</v-btn>
		</v-row>

		<v-snackbar v-model="error.status">
			{{ error.message }}

			<template v-slot:action="{ attrs }">
				<v-btn color="white" text v-bind="attrs" @click="error.status = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
import TitlePage from '@/components/TitlePage'
import { createDoctors } from '@/utils/doctors'

export default {
	name: 'PrivateDoctorCreateView',
	components: { TitlePage },
	data: () => ({
		noEmptyRule: [(v) => !!v || 'Campo Requerido'],
		name: '',
		observations: '',
		telephone: '',
		area: '',
		specialism: '',
		center: '',
		modal: false,
		loading: false,
		error: {
			status: false,
			message: '',
		},
	}),
	methods: {
		save() {
			const form = this.$refs.form
			const validate = form.validate()

			if (validate) {
				const doctor = {
					name: this.name,
					observations: this.observations,
					telephone: this.telephone,
					area: this.area,
					specialism: this.specialism,
					center: this.center,
				}

				this.loading = true

				createDoctors(doctor)
					.then(() => {
						this.$router.push('/private/doctors')
					})
					.catch((err) => {
						console.log(err)
						this.error.status = true
						this.error.message = 'Se ha producido un error'
					})
					.finally(() => {
						this.loading = false
					})
			}
		},
	},
}
</script>

<style scoped></style>
