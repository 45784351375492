import {BASE_URL} from "@/utils/globals";

const BACK_LINK_PREFIX = BASE_URL+'/uploads/pages/attachments/';

export class PageFile {
  constructor(id, title, filePath) {
    this.id = id;
    this.title = title;
    this.filePath = filePath;
  }

  static createFromAPI(request){
    var collection = []
    request.forEach( element => (
      collection.push(
        new PageFile(element.id, element.title, BACK_LINK_PREFIX+element.filePath)
      )
    ));

    return collection;
  }
}
