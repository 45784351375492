import ApiService from '@/service/api.service'

const days = [
	'Domingo',
	'Lunes',
	'Martes',
	'Miercoles',
	'Jueves',
	'Viernes',
	'Sabado',
]

const months = [
	'Enero',
	'Febrero',
	'Marzo',
	'Abril',
	'Mayo',
	'Junio',
	'Julio',
	'Agosto',
	'Septiembre',
	'Octubre',
	'Noviembre',
	'Diciembre',
]

export const getEvents = (page) => {
	return ApiService.get(`/events?page=${page}`).then((res) => {
		const { data } = res
		const events = data['hydra:member']

		return [
			events.map((item) => {
				const date = new Date(item.date)
				const day = days[date.getDay()]
				const month = months[date.getMonth()]

				return {
					...item,
					date: `${day} ${date.getDate()} de ${month} de ${date.getFullYear()}`,
				}
			}),
			data['hydra:totalItems'],
		]
	})
}

export const getEvent = (id) => {
	return ApiService.get(`/events/${id}`).then((res) => {
		let { data } = res

		return {
			...data,
			date: data.date.substr(0, 10),
		}
	})
}

export const createEvent = (data) => {
	return ApiService.post(`/events`, data)
}

export const deleteEvent = (id) => {
	return ApiService.delete(`/events/${id}`)
}

export const updateEvent = (id, data) => {
	return ApiService.put(`/events/${id}`, data)
}
