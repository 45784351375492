<template>
	<v-app>
    <v-navigation-drawer app v-model="drawer">
      <router-link to="/">
        <v-img
            :src="require('./assets/logo_supervivientes.png')"
            max-width="200"
            contain
            class="mx-auto my-4"
        />
      </router-link>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Área Común </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
            v-for="item in public_items"
            :key="item.title"
            :to="item.route"
            link
        >
          <v-list-item-icon>
            <v-img max-width="25" :src="getImgUrl(item.icon)" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              Área Privada
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list v-if="store.getters['auth/isLoggedIn']">
          <v-list-item
              v-for="item in private_items"
              :key="item.title"
              link
              :to="item.route"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div v-else>
          <v-list>
            <v-list-item
                v-for="item in private_public_items"
                :key="item.title"
                link
                :to="item.route"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-navigation-drawer>

		<v-app-bar app color="#434544" dark>
			<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<v-spacer></v-spacer>
			<router-link to="/salud">
				<v-img
					class="mx-4"
					max-width="30"
					src="./assets/menu/icono_emocion_blanco.png"
				></v-img>
			</router-link>
			<router-link to="/information">
				<v-img
					class="mx-4"
					max-width="30"
					src="./assets/menu/informacion_blanco.png"
				></v-img>
			</router-link>
			<router-link to="/contact">
				<v-img
					class="mx-4"
					max-width="30"
					src="./assets/menu/dialogo_blanco.png"
				></v-img>
			</router-link>
			<router-link to="/private">
				<v-img
					class="mx-4"
					max-width="30"
					src="./assets/menu/login_blanco.png"
				></v-img>
			</router-link>
		</v-app-bar>

		<v-main>
      <v-banner
          v-if="deferredPrompt"
          color="#05904a"
          dark
      >
        ¿Quieres descargar la aplicación?
        <template v-slot:actions>
          <v-btn text @click="dismiss">Cancelar</v-btn>
          <v-btn text @click="install">Descargar</v-btn>
        </template>
      </v-banner>
			<v-container pa-0 fluid>
        <router-view></router-view>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import store from '@/store'

export default {
	name: 'App',
	components: {},
	methods: {
		getImgUrl(img) {
			return require('@/assets/menu/' + img + '.png')
		},
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    }
	},
  created() {
    window.addEventListener("beforeinstallprompt", e => {
      console.log("beforeinstallprompt")
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
	data: () => ({
    deferredPrompt: null,
		drawer: false,
		public_items: [
			{ title: 'Salud', icon: 'icono_emocion_gris', route: '/salud' },
			{
				title: 'Estar al día',
				icon: 'informacion_gris',
				route: '/information',
			},
			{ title: 'Consultas y Foro', icon: 'dialogo_gris', route: '/contact' },
		],
		private_items: [
			{ title: 'Agenda', icon: 'fa-calendar', route: '/private/events' },
			{
				title: 'Medicamentos',
				icon: 'fa-capsules',
				route: '/private/medicines',
			},
			{ title: 'Documentos', icon: 'fa-file', route: '/private/documents' },
			{ title: 'Especialistas', icon: 'fa-heart', route: '/private/doctors' },
			{ title: 'Pasaporte', icon: 'fa-id-card', route: '/private/passport' },
		],
		private_public_items: [
			{ title: 'Acceso', icon: 'fa-user', route: '/login' },
			{ title: 'Registro', icon: 'fa-user-plus', route: '/register' },
		],
		store: store,
	}),
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
  font-family: 'Open Sans', sans-serif;
}

.page-title {
  font-family: 'Roboto Slab', sans-serif;
  font-size: 35px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.salud-color {
 color: #43955d;
}

.external-link {
  text-decoration: none;
  font-weight: bold;
}

.information-color {
  color: #546ca4 !important;
}

.private-color {
  color: #D22630 !important;
}

.question-color {
  color: #b29e38 !important;
}
</style>
