<template>
  <v-bottom-navigation fixed>
    <v-btn to="/private/passport">
      <span>Pasaporte</span>
      <v-icon>fa-id-card</v-icon>
    </v-btn>

    <v-btn to="/private/profile">
      <span>Perfil</span>
      <v-icon>fa-user</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "ProfileTabMenu"
}
</script>

<style scoped>

</style>
