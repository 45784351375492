import AuthService from '@/service/auth.service';



function checkLocalStorage(){

  var initialState = null;
  try {

    const localStorageToken = JSON.parse(localStorage.getItem('user'));

    if(
        localStorageToken &&
        ('token' in localStorageToken) &&
        ('refreshToken' in localStorageToken) &&
        ('user_id' in localStorageToken)
    ){
      initialState = {status: {loggedIn: true}, user: { ...localStorageToken }}
    }

  }catch (e){
    console.log(e.message)
  }

  if(!initialState) {
    initialState = {status: {}, user: null};
    localStorage.setItem('user', JSON.stringify(initialState))
  }

  return initialState
}

const initialState = checkLocalStorage()

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    LogIn({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          return Promise.reject(error.response.data);
        }
      );
    },
    Logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    Register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error.response.data);
        }
      );
    },
    RefreshToken({ commit }, accessToken) {
      commit('refreshToken', accessToken)
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    },
    registerSuccess(state) {
      state.status = {};
    },
    registerFailure(state) {
      state.status = {};
    },
    refreshToken(state, accessToken) {
      state.status = { loggedIn: true };
      state.user.accessToken = accessToken.refresh_token;
      state.user.token = accessToken.token;
    }
  },
  getters: {
    isLoggedIn(state) {
      return state.status.loggedIn;
    },
    userToken(state) {
      return state.user.token;
    },
    userRefreshToke(state) {
      return state.user.refreshToken;
    },
    userId(state) {
      return state.user.user_id
    }
  }

};
