<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#546ca4'"
			:title="'ESTAR AL DÍA'"
			:subtitle="'Información esencial'"
			:img="'informacion_blanco'"
		/>

		<v-sheet class="mx-auto pa-4" color="white" elevation="0">
			<h1 class="page-title information-color" >¿Nos Ayudas?</h1>
			<p>
				Si quieres colaborar con ASPANION puedes hacerlo de manera muy sencilla,
				en nuestra web
				<a class="external-link information-color" href="https://aspanion.es/como-colaborar/"
					>te explicamos todas las opciones</a
				>
			</p>

			<section class="collaborate-links-list mt-6">
				<CollaborateLink
					:title="'HAZTE SOCIO'"
					:link="'https://aspanion.es/hazte-socio-colaborador/'"
					:backgroundColor="'#22a0d4'"
					:content="'Con las ayudas sostenidas en el tiempo podemos plantear proyectos a medio y largo plazo.'"
					:footerTitle="'¡Marca una sonrisa!'"
					:width="'190px'"
				/>

				<CollaborateLink
					:title="'HAZTE VOLUNTARIO'"
					:link="'https://aspanion.es/hazte-voluntario/'"
					:backgroundColor="'#05904a'"
					:content="'Los voluntarios son fundamentales en ASPANION. Si deseas ayudar a las familias afectadas por el cáncer infantil este es tu lugar'"
					:footerTitle="'¿TE APUNTAS?'"
					:width="'300px'"
				/>

				<CollaborateLink
					:title="'HAZ UNA DONACIÓN'"
					:link="'https://aspanion.es/haz-una-donacion/'"
					:backgroundColor="'#c1a416'"
					:content="'Toda aportación, por pequeña que sea, suma. Entre todos podemos conseguir mejorar la vida de quienes sufren por el cáncer infantil'"
					:footerTitle="'¿NOS AYUDAS?'"
					:width="'190px'"
				/>

				<CollaborateLink
					:title="'TESTAMENTO SOLIDARIO'"
					:link="'https://aspanion.es/hazte-voluntario/'"
					:backgroundColor="'#d97c18'"
					:content="'Dejar parte de tu herencia a los menores con cáncer y sus familias es posible. Tu legado puede ser útil para quienes sufren por esta dura enfermedad'"
					:footerTitle="'¿QUIERES SABER CÓMO?'"
					:width="'300px'"
				/>

				<CollaborateLink
					:title="'EMPRESA COLABORADORA'"
					:link="'https://aspanion.es/hazte-socio-colaborador/'"
					:backgroundColor="'#7f549f'"
					:content="'Como empresa puedes participar en el programa de Responsabilidad Social Comparativa que hemos diseñado. ¡Ayúdanos a hacer este un mundo mejor!'"
					:footerTitle="'¡Infórmate!'"
					:width="'300px'"
				/>

				<CollaborateLink
					:title="'ORGANIZA UN EVENTO'"
					:link="'https://aspanion.es/hazte-voluntario/'"
					:backgroundColor="'#0f988e'"
					:content="'Una carrera solidaria, un festival, un desfile... si quieres saber cómo organizar tu evento solidario a favor de ASPANION puedes contar con nuestro asesoramiento'"
					:footerTitle="'¿TIENES UNA IDEA?'"
					:width="'300px'"
				/>
			</section>

			<p class="mt-6">
				Entre otras cosas puedes ser voluntaria o voluntario, socio colaborador,
				organizar eventos o actos solidarios, o simplemente compartir nuestras
				publicaciones para hacer llegar la realidad del cáncer infantil y de los
				supervivientes más lejos.
			</p>
		</v-sheet>
	</div>
</template>

<script>
import TitlePage from '@/components/TitlePage.vue'
import CollaborateLink from '@/components/CollaborateLink.vue'

export default {
	name: 'CollaborateView',
	components: {
		TitlePage,
		CollaborateLink,
	},
}
</script>

<style scoped>
.collaborate-links-list {
	width: 100%;
	display: grid;
	grid-template-columns: 100%;
	gap: 15px;
}

@media screen and (min-width: 600px) {
	.collaborate-links-list {
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and (min-width: 1080px) {
	.collaborate-content {
		padding: 24px 0;
	}
}

@media screen and (min-width: 800px) {
	.collaborate-links-list {
		grid-template-columns: 1fr 1fr;
		gap: 20px;
	}
}
</style>
