<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#D22630'"
			:title="'AREA PRIVADA'"
			:subtitle="'Herramientas para el día a día.'"
			:img="'login_blanco'"
		/>
		<v-row class="mt-2" no-gutters>
			<div class="col-md-6 col-sm-6 col-xs-12">
				<v-card class="ma-2" elevation="0">
					<v-img
						:src="require('../assets/private/agenda_citas.jpg')"
						class="white--text align-center"
						contain
					>
						<div class="text-center">
							<v-btn
								to="/private/events"
								class="white--text px-16 py-8 salud-btn"
								outlined
								>Agenda
							</v-btn>
						</div>
					</v-img>
				</v-card>
			</div>
			<div class="col-md-6 col-sm-6 col-xs-12">
				<v-card class="ma-2" elevation="0">
					<v-img
						:src="require('../assets/private/medicamentos.jpg')"
						class="white--text align-center"
						contain
					>
						<div class="text-center">
							<v-btn
								to="/private/medicines"
								class="white--text px-16 py-8 salud-btn"
								outlined
							>
								Medicamentos
							</v-btn>
						</div>
					</v-img>
				</v-card>
			</div>
			<div class="col-md-6 col-sm-6 col-xs-12">
				<v-card class="ma-2" elevation="0">
					<v-img
						:src="require('../assets/private/documentos_medicos.jpg')"
						class="white--text align-center"
						contain
					>
						<div class="text-center">
							<v-btn
								to="/private/documents"
								class="white--text px-16 py-8 salud-btn"
								outlined
							>
								Documentos
							</v-btn>
						</div>
					</v-img>
				</v-card>
			</div>
			<div class="col-md-6 col-sm-6 col-xs-12">
				<v-card class="ma-2" elevation="0">
					<v-img
						:src="require('../assets/private/mis_especialistas.jpg')"
						class="white--text align-center"
						contain
					>
						<div class="text-center">
							<v-btn
								to="/private/doctors"
								class="white--text px-16 py-8 salud-btn"
								outlined
							>
								Especialistas
							</v-btn>
						</div>
					</v-img>
				</v-card>
			</div>
		</v-row>
	</div>
</template>

<script>
import TitlePage from '@/components/TitlePage'

export default {
	name: 'PrivateAreaView',
	components: { TitlePage },
}
</script>

<style scoped>
.salud-btn {
	background-color: rgb(0, 0, 0, 0.4);
}
</style>
