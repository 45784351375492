export class PageSection {
  constructor(id, title, content) {
    this.id = id;
    this.title = title;
    this.content = content;
  }

  static createFromAPI(request){
    var collection = []
    request.forEach( element => (
      collection.push(
        new PageSection(element.id, element.title, element.content)
      )
    ));

    return collection;
  }
}
