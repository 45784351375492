<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#546ca4'"
			:title="'ESTAR AL DÍA'"
			:subtitle="'Información esencial'"
			:img="'informacion_blanco'"
		/>

		<div v-if="loading">
			<div class="text-center">
				<v-progress-circular
					indeterminate
					color="#546ca4"
					class="mt-6"
				></v-progress-circular>
			</div>
		</div>

		<div v-else-if="!event || error">
			<EmptyState />
		</div>

		<section v-else>
			<v-img height="150px" :src="event.getImgUrl()"> </v-img>
			<time :datetime="date" class="event-view-info-date">{{ date }}</time>
      <h1 class="px-4 page-title information-color">{{ event.name }}</h1>
			<div v-html="event.content" class="event-view-content"></div>
		</section>
	</div>
</template>

<script>
import axios from 'axios'
import TitlePage from '@/components/TitlePage.vue'
import { Event } from '@/model/Event'
import { API_URL } from '@/utils/globals'
import EmptyState from '@/components/EmptyState.vue'

export default {
	name: 'ListEventsView',
	components: {
		TitlePage,
		EmptyState,
	},
	data() {
		return {
			loading: true,
			error: false,
			event: null,
			date: '',
		}
	},
	created() {
		const { id } = this.$route.params

		axios
			.get(`${API_URL}/global_events/${id}`)
			.then((res) => {
				const { data } = res

				this.event = Event.createCollectionFromAPI([data])[0]
				this.date = this.event.getDate()
			})
			.catch((err) => {
				console.log(err)
				this.error = true
			})
			.finally(() => {
				this.loading = false
			})
	},
}
</script>

<style scoped>
.event-view-img {
	width: 100%;
}

.event-view-img-container {
	width: 100%;
	max-height: 500px;
	overflow: hidden;
	display: flex;
	align-items: center;
}


.event-view-content {
	padding: 10px 15px;
  font-size: 16px;
  line-height: 1.5;
}

.event-view-info-date {
	display: block;
	font-size: 14px;
	padding: 15px 15px 0 15px;
}

@media screen and (min-width: 1050px) {
	.event-view-title {
		padding: 10px 0;
	}

	.event-view-content {
		padding: 10px 0;
	}

	.event-view-info-date {
		padding: 15px 0 0 0;
	}
}
</style>
