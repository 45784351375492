<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#546ca4'"
			:title="'ESTAR AL DÍA'"
			:subtitle="'Información esencial'"
			:img="'informacion_blanco'"
		/>

		<div v-if="loading">
			<div class="text-center">
				<v-progress-circular
					indeterminate
					color="#546ca4"
					class="mt-6"
				></v-progress-circular>
			</div>
		</div>

		<div v-else-if="testimonies.length <= 0 || error">
			<EmptyState />
		</div>

		<v-carousel v-else height="auto" hide-delimiters
			><template v-slot:prev="{ on, attrs }">
				<v-btn class="carousel-button" v-bind="attrs" v-on="on"
					><v-icon>fa-chevron-left</v-icon></v-btn
				>
			</template>
			<template v-slot:next="{ on, attrs }">
				<v-btn class="carousel-button" v-bind="attrs" v-on="on"
					><v-icon>fa-chevron-right</v-icon></v-btn
				>
			</template>
			<v-carousel-item v-for="testimony in testimonies" :key="testimony.id">
				<div class="testimony-carousel-content">
					<div class="mx-auto" max-width="700">
						<iframe
							class="testimony-video"
							:src="`https://www.youtube.com/embed/${testimony.link
								.split('/')
								.pop()}`"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						>
						</iframe>

						<v-card-text class="text--primary">
              <h1 class="page-title information-color">{{ testimony.title }}</h1>
							<div class="testimony-content" v-html="testimony.description"></div>
						</v-card-text>
					</div>
				</div>
			</v-carousel-item>
		</v-carousel>
	</div>
</template>

<script>
import axios from 'axios'
import { Testimony } from '@/model/Testimony'
import TitlePage from '@/components/TitlePage.vue'
import EmptyState from '@/components/EmptyState.vue'

export default {
	name: 'TestimoniesView',
	data() {
		return {
			testimonies: [],
			loading: true,
			error: false,
		}
	},
	components: {
		TitlePage,
		EmptyState,
	},
	created() {
		axios
			.get('https://private.supervivientescancerinfantil.es/api/testimonies')
			.then((response) => {
				this.testimonies = Testimony.createCollectionFromAPI(
					response.data['hydra:member']
				)

				this.loading = false
			})
			.catch((err) => {
				console.log(err)
				this.error = true
			})
			.finally(() => {
				this.loading = false
			})
	},
}
</script>

<style scoped>
.testimony-carousel-content {
	padding: 20px;
}

.testimony-video {
	width: 100%;
	height: 300px;
}

.testimony-content{
  text-align: left;
  font-size: 16px;
  line-height: 1.5;
}

.carousel-button {
	border-radius: 50%;
	padding: 0 !important;
	min-width: 40px !important;
	height: 40px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #0005 !important;
}

.carousel-button:hover {
	opacity: 1 !important;
	background-color: #0009 !important;
}

@media screen and (min-width: 750px) {
	.testimony-carousel-content {
		padding: 50px 100px;
	}
}
</style>
