import ApiService from '@/service/api.service'

export const getUser = (id) => {
	return ApiService.get(`/users/${id}`).then((res) => {
		const { data } = res

		return data
	})
}

export const updateUser = (data, id) => {
	return ApiService.put(`/users/${id}`, data)
}
