<template>
	<a
		:href="link"
		class="collaborate-link"
		:style="{ backgroundColor: backgroundColor }"
	>
		<header>
			<h3 v-html="title" class="collaborate-link-title"></h3>
		</header>
		<main>
			<p class="collaborate-link-content">{{ content }}</p>
		</main>
		<footer>
			<span class="collaborate-link-footer-title">{{ footerTitle }}</span>
		</footer>
	</a>
</template>

<script>
export default {
	name: 'CollaborateLink',
	props: {
		link: String,
		img: String,
		backgroundColor: String,
		title: String,
		content: String,
		footerTitle: String,
		width: String,
	},
	methods: {
		getImgUrl() {
			/* return require('@/assets/menu/' + this.img + '.png') */
		},
	},
}
</script>

<style scoped>
.collaborate-link {
	color: #fff;
	text-decoration: none;
	text-align: center;
	width: 100%;
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 80px 1fr 30px;
	justify-content: center;
	padding: 15px;
	gap: 15px;
}

.collaborate-link header {
	widows: 100%;
	border-bottom: solid 4px #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.collaborate-link-title {
	margin: auto;
	text-align: center;
	font-size: 23px;
	font-weight: 600;
}

.collaborate-link main {
	widows: 100%;
}

.collaborate-link-content {
	font-size: 15px;
	text-align: left;
}

.collaborate-link footer {
	display: flex;
	justify-content: space-between;
	height: 100%;
	align-items: center;
}

.collaborate-link-footer-title {
	font-weight: 700;
	font-size: 14px;
	text-transform: uppercase;
}

@media screen and (min-width: 600px) {
	.collaborate-link {
		grid-template-rows: 90px 1fr 30px;
	}

	.collaborate-link-title {
		width: 200px;
		font-size: 25px;
	}

	.collaborate-link-footer-title {
		font-size: 15px;
	}
}

@media screen and (min-width: 800px) {
	.collaborate-link {
		grid-template-rows: 130px 1fr 60px;
		gap: 25px;
	}

	.collaborate-link-title {
		width: 270px;
		font-size: 35px;
	}

	.collaborate-link-footer-title {
		font-size: 21px;
	}

	.collaborate-link {
		padding: 25px;
	}

	.collaborate-link-content {
		font-size: 19px;
	}
}
</style>
