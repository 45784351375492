<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#b29e38'"
			:title="'CONSULTAS Y FORO'"
			:subtitle="'Comparte experiencias y resuelve dudas'"
			:img="'dialogo_blanco'"
		/>

		<section class="pa-4">
			<h1 class="page-title question-color">Consultas y dudas</h1>
			<p>
				ASPANION es la Asociación de Madres y Padres de Niñas y Niños con Cáncer
				de la Comunidad Valenciana. La entidad nace en 1985 y ofrece a los
				menores afectados y a sus madres, padres y hermanos apoyo psicológico,
				social y económico. ASPANION ofrece sus recursos y ayuda desde el
				diagnóstico y en todas las fases de la enfermedad, incluso en el duelo.
				El principal objetivo de la ONG es mejorar la calidad de vida los
				afectados.
			</p>

		<v-form ref="form" @submit.prevent="checkForm">

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            name="questionFormName"
            label="Nombre"
            v-model="name"
            :rules="noEmptyRule"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            name="questionFormEmail"
            label="Email"
            v-model="email"
            :rules="emailRules"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            name="questionFormTelephone"
            label="Teléfono"
            v-model="phone"
            :rules="phoneRules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            :items="queryEmails"
            label="Típo de Consulta"
            :rules="noEmptyRule"
            @change="setQuery"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
        <v-textarea
          name="questionFormMessage"
          label="Mensaje"
          v-model="message"
          :rules="noEmptyRule"
          required
        ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-checkbox
              v-model="checkbox"
              label="He leído y acepto la política de privacidad"
              :rules="checkboxRules"
          ></v-checkbox>
          <p>
            <small>
              De conformidad con la normativa en materia de protección de datos de carácter
              personal, los datos que nos proporcione serán tratados por la Asociación de Padres
              de Niños con Cáncer de la Comunidad Valenciana (en adelante, ASPANION), y los
              utilizará con la finalidad de gestionar el tratamiento de datos de la presente
              solicitud. Sus datos no serán cedidos a terceros, salvo por obligaciones legales
              expresas, y los datos serán conservados durante los plazos legalmente exigibles a
              ASPANION, así como para la defensa y atención de reclamaciones y
              responsabilidades. Podrá ejercer sus derechos de protección de datos a través
              de <a class="external-link question-color" href="mailto:valencia@aspanion.es">valencia@aspanion.es</a>. Al pulsar “Enviar” usted está autorizando el tratamiento
              de sus datos. Más información sobre el tratamiento de datos dirigiéndose a nuestra
              asociación o en la política de privacidad de <a class="external-link question-color" href="https://aspanion.es/politica-de-privacidad/" target="_blank">www.aspanion.es</a>. (Reglamento UE
              2016/679)
            </small>
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="#b29e38"
            class="mt-6 white--text"
            type="submit"
          >
            Enviar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    </section>
    <v-snackbar v-model="successMessage.status">
			{{ successMessage.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="successMessage.status = false"
				>
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>

		<v-snackbar v-model="error.status">
			{{ error.message }}

			<template v-slot:action="{ attrs }">
				<v-btn color="white" text v-bind="attrs" @click="error.status = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
import TitlePage from '@/components/TitlePage'
import { API_URL } from '@/utils/globals'
import axios from 'axios'

export default {
	name: 'QuestionsView',
	components: { TitlePage },
	data: () => ({
		noEmptyRule: [(v) => !!v || 'Campo Requerido'],
		emailRules: [
			(v) => !!v || 'E-mail requerido',
			(v) => /.+@.+\..+/.test(v) || 'E-mail tiene que ser valido',
		],
		phoneRules: [
			(v) => !isNaN(v) || 'El numero tiene que ser valido',
			(v) => v.length >= 9 || 'El numero tiene que ser valido',
		],
    checkboxRules: [
      (v) => v === true || 'Debes aceptar la política de privacidad'
    ],
		email: '',
		name: '',
		phone: '',
		message: '',
		queries: [],
		queryEmails: [],
		querySelected: '',
    checkbox: false,
		successMessage: {
			status: false,
			message: '',
		},
		error: {
			status: false,
			message: '',
		},
		loading: false,
	}),
	created() {
		axios.get(`${API_URL}/email_types`).then((res) => {
			const data = res.data['hydra:member']

			this.queries = data

			this.queryEmails = data.map((item) => item.name)
		})
	},
	methods: {
		checkForm() {
			const form = this.$refs.form
			const validate = form.validate()

			this.successMessage.message = ''
			this.error.message = ''

			if (validate) {
				this.loading = true

				const body = {
					name: this.name,
					email: this.email,
					phone: this.phone,
					message: this.message,
					emailType: this.querySelected,
				}

				axios
					.post(`${API_URL}/custom/send-email`, body)
					.then(() => {
						this.successMessage.status = true
						this.successMessage.message = 'El mensaje se ha enviado'
						form.reset()
					})
					.catch((err) => {
						this.error.status = true
						this.error.message = 'Ha habido un error'
						console.log(err)
					})
					.finally(() => {
						this.loading = false
					})
			}
		},
		setQuery(value) {
			this.querySelected = this.queries.find((item) => item.name === value).id
		},
	},
}
</script>

<style scoped>
.question-message {
	text-align: center;
	display: block;
}

</style>
