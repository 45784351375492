import apiService from "@/service/api.service";

const setup = (store) => {
    apiService.interceptors.request.use(
        (config) => {
            const token = store.getters['auth/userToken']
            if (token && config.url !== '/token/refresh') {
                config.headers["Authorization"] = 'Bearer ' + token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    apiService.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (err.response) {

                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    try {
                        const rs = await apiService.post("/token/refresh", {
                            refresh_token: store.getters['auth/userRefreshToke']
                        });

                        store.dispatch('auth/RefreshToken', rs.data);
                        return apiService(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }
            return Promise.reject(err);
        }
    );
};
export default setup;
