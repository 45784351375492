<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#43955d'"
			:title="'SALUD'"
			:subtitle="'Cuída tu salud física y emocional'"
			:img="'icono_emocion_blanco'"
		/>
		<div class="text-container">
			<div v-if="loading">
				<div class="text-center">
					<v-progress-circular
						indeterminate
						color="green"
						class="mt-6"
					></v-progress-circular>
				</div>
			</div>

			<div v-else-if="!contentPage || error">
				<EmptyState />
			</div>

			<div v-else>
				<section class="pa-4">
					<h1 class="page-title salud-color">{{ contentPage.name }}</h1>
					<div class="salud-section-content" v-html="contentPage.content"></div>
				</section>
				<section>
					<ContentPageSection
						class="content-page-section"
						v-for="section in contentPage.pageSections"
						:title="section.title"
						:content="section.content"
						:key="section.id"
					/>

					<ContentPageGuias
						v-if="contentPage.pageFiles.length"
						:title="'GUÍAS ÚTILES'"
						:list="contentPage.pageFiles"
					/>

					<ContentPageFuentes
						v-if="contentPage.pageLinks.length"
						:title="'FUENTES'"
						:list="contentPage.pageLinks"
					/>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import { ContentPage } from '@/model/ContentPage'
import TitlePage from '@/components/TitlePage'
import ContentPageSection from './ContentPageSection.vue'
import ContentPageGuias from './ContentPageGuias.vue'
import ContentPageFuentes from './ContentPageFuentes.vue'
import EmptyState from './EmptyState.vue'
import {API_URL} from "@/utils/globals";

export default {
	components: {
		TitlePage,
		ContentPageSection,
		ContentPageGuias,
		ContentPageFuentes,
		EmptyState,
	},
	props: ['page_key'],
	data: () => ({
		loading: false,
		error: false,
		contentPage: null,
	}),
	name: 'ContentPage',
	mounted() {
		this.loading = true
		axios
			.get(
				`${API_URL}/pages`,{
          params: {
            appKey: this.page_key
          }
        }
			)
			.then((response) => {
				this.contentPage = ContentPage.createFromAPI(
					response.data['hydra:member']
				)
			})
			.catch((err) => {
				console.log(err)
				this.error = true
			})
			.finally(() => (this.loading = false))
	},
}
</script>

<style scoped>
.salud-section-content >>> img {
  width: calc(100% + 30px);
  margin-left: calc(-15px);
  margin-right: calc(-15px);
}

.content-page-section >>> img {
	width: 100%;
}

.salud-section-title {
	color: #43955d;
	font-size: 35px;
	padding: 0 0 20px 0;
  font-family: 'Roboto Slab', sans-serif;
}

.salud-section-content img {
	display: none;
}

@media screen and (min-width: 1100px) {
	.salud-section {
		padding: 20px 0;
	}
}
</style>
