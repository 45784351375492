<template>
	<v-main>
		<v-container fluid fill-height>
			<v-layout align-center justify-center>
				<v-flex xs12 sm6 md6>
					<v-card class="elevation-1">
						<v-toolbar dark color="#434544">
							<v-toolbar-title>Registro</v-toolbar-title>
						</v-toolbar>
						<v-card-text>
							<v-form ref="form" @submit.prevent="register" id="login-form">
								<v-text-field
									v-model="user.name"
									prepend-icon="fa-user"
									name="name"
									label="Nombre"
									type="text"
									:rules="nameRules"
								></v-text-field>
								<v-text-field
									v-model="user.email"
									prepend-icon="fa-user"
									name="email"
									label="Email"
									type="text"
									:rules="emailRules"
								></v-text-field>
								<v-text-field
									v-model="user.password"
									id="password"
									prepend-icon="fa-lock"
									name="password"
									label="Contraseña"
									type="password"
									:rules="passwordRules"
								></v-text-field>
								<v-text-field
									v-model="user.repeatPassword"
									id="repeatPassword"
									prepend-icon="fa-lock"
									name="password"
									label="Repetir Contraseña"
									type="password"
									:rules="[matchingPasswords]"
								></v-text-field>
                <v-checkbox
                    v-model="checkbox"
                    label="He leído y acepto la política de privacidad"
                    :rules="checkboxRules"
                ></v-checkbox>
							</v-form>
              <p>
                <small>
                  De conformidad con la normativa en materia de protección de datos de carácter
                  personal, los datos que nos proporcione serán tratados por la Asociación de Padres
                  de Niños con Cáncer de la Comunidad Valenciana (en adelante, ASPANION), y los
                  utilizará con la finalidad de gestionar el tratamiento de datos de la presente
                  solicitud. Sus datos no serán cedidos a terceros, salvo por obligaciones legales
                  expresas, y los datos serán conservados durante los plazos legalmente exigibles a
                  ASPANION, así como para la defensa y atención de reclamaciones y
                  responsabilidades. Podrá ejercer sus derechos de protección de datos a través
                  de <a class="external-link private-color" href="mailto:valencia@aspanion.es">valencia@aspanion.es</a>. Al pulsar “Enviar” usted está autorizando el tratamiento
                  de sus datos. Más información sobre el tratamiento de datos dirigiéndose a nuestra
                  asociación o en la política de privacidad de <a class="external-link private-color" href="https://aspanion.es/politica-de-privacidad/" target="_blank">www.aspanion.es</a>. (Reglamento UE
                  2016/679)
                </small>
              </p>
						</v-card-text>
						<v-card-actions>
							<v-btn
								elevation="0"
								plain
								type="submit"
								form="login-form"
								to="login"
							>
								Ya tengo cuenta
							</v-btn>
							<v-spacer></v-spacer>
							<v-btn
								type="submit"
								form="login-form"
								:loading="loading"
								:disabled="loading"
							>
								Registrarse
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-snackbar v-model="error.status">
			{{ this.error.message }}

			<template v-slot:action="{ attrs }">
				<v-btn color="white" text v-bind="attrs" @click="error.status = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
	</v-main>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'RegisterView.vue',
	data: () => ({
		user: {
			name: '',
			email: '',
			password: '',
			repeatPassword: '',
		},
		loading: false,
		error: {
			status: false,
			message: '',
		},
		nameRules: [(v) => !!v || 'Campo obligatorio'],
		emailRules: [
			(v) => !!v || 'Campo obligatorio',
			(v) =>
				/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
					v
				) || 'E-mail no válido',
		],
		passwordRules: [
			(v) => !!v || 'Campo obligatorio',
			(v) => v.length >= 8 || 'La contraseña es demasiado corta',
		],
		repeatPasswordRules: [(v) => !!v || 'Campo obligatorio'],
    checkboxRules: [
      (v) => v === true || 'Debes aceptar la política de privacidad'
    ],
	}),
	methods: {
		...mapActions('auth', ['Register']),
		matchingPasswords() {
			return this.user.password === this.user.repeatPassword
				? true
				: 'Las contraseñas no coinciden'
		},
		register() {
			let isFormValid = this.$refs.form.validate()
			if (isFormValid) {
				this.loading = true
				this.Register(this.user)
					.catch((error) => {
						if (Object.hasOwn(error, 'violations')) {
							this.error.message = error.violations[0].message
						}
						this.error.status = true
					})
					.then(() => {
						this.$router.push('/login')
					})
					.finally(() => {
						this.loading = false
					})
			}
		},
	},
}
</script>

<style scoped></style>
