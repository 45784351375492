import axios from "axios";
import { API_URL } from '@/utils/globals'

class AuthService {

  register(inputUser) {
    const data = {  "name": inputUser.name, 'email': inputUser.email, 'plainPassword': inputUser.password }
    return axios.post(API_URL + "/users", data)
  }

  login(inputUser) {
    const payload = { "username": inputUser.email, "password": inputUser.password };
    return axios.post(API_URL + "/login_check", payload).then(response => {
      if (response.data.token && response.data.refresh_token) {
        var user = {
          token: response.data.token,
          refreshToken: response.data.refresh_token,
          user_id: response.data.data.user_id
        }
        localStorage.setItem('user', JSON.stringify(user))
      }

      return user
    });
  }

  refreshToken(token){
    let user = JSON.parse(localStorage.getItem('user'))
    user.token = token
    localStorage.setItem('user', JSON.stringify(user))
  }

  logout() {
    localStorage.removeItem('user')
  }

}

export default new AuthService()
