<template>
	<div
		class="d-flex pa-4 title-page"
		:style="{ backgroundColor: backgroundColor }"
	>
		<img :alt="title" class="title-page-img" :src="getImgUrl()" />
		<div :style="{ color: color }">
			<h1 class="title-page-title">{{ title }}</h1>
			<h2 class="title-page-subtitle" :style="{ color: color }">
				{{ subtitle }}
			</h2>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TitlePage',
	props: {
		img: String,
		title: String,
		subtitle: String,
		color: String,
		backgroundColor: String,
	},
	methods: {
		getImgUrl() {
			return require('@/assets/menu/' + this.img + '.png')
		},
	},
}
</script>

<style scoped>
.title-page {
	display: flex;
	align-items: center;
}

.title-page-img {
	max-width: 100px;
	max-height: 50px;
	margin: 0 16px 0 0;
}

.title-page-title {
	font-size: 26px;
	font-weight: 600;
	line-height: 32px;
	text-shadow: 1px 1px 4px #0006;
  font-family: 'Roboto Slab', sans-serif;
}

.title-page-subtitle {
	font-size: 18px;
	line-height: 20px;
	font-weight: 400;
	text-shadow: 1px 1px 4px #0006;
  font-family: 'Roboto Slab', sans-serif;
}
</style>
