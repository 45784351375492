<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#43955d'"
			:title="'SALUD'"
			:subtitle="'Cuída tu salud física y emocional'"
			:img="'icono_emocion_blanco'"
		/>
		<v-row class="mt-2" no-gutters>
      <v-col cols="12" sm="6">
				<v-card class="pa-2" elevation="0">
					<v-img
						:src="require('../assets/salud/habitos_saludables.jpg')"
						class="white--text align-center"
						contain
					>
						<div class="text-center">
							<v-btn
								to="/salud/habits"
                class="white--text px-10 py-8 salud-btn"
								outlined
								>Hábitos saludables
							</v-btn>
						</div>
					</v-img>
				</v-card>
      </v-col>
      <v-col cols="12" sm="6">
				<v-card class="pa-2" elevation="0">
					<v-img
						:src="require('../assets/salud/nutricion.jpg')"
						class="white--text align-center"
						contain
					>
						<div class="text-center">
							<v-btn
								to="/salud/nutrition"
                class="white--text px-10 py-8 salud-btn"
								outlined
							>
								Nutrición
							</v-btn>
						</div>
					</v-img>
				</v-card>
      </v-col>
      <v-col cols="12" sm="6">
				<v-card class="pa-2"  elevation="0">
					<v-img
						:src="require('../assets/salud/salud_sexual.jpg')"
						class="white--text align-center"
						contain
					>
						<div class="text-center">
							<v-btn
								to="/salud/sexual"
                class="white--text px-10 py-8 salud-btn"
								outlined
							>
								Salud sexual <br />y reproductiva
							</v-btn>
						</div>
					</v-img>
				</v-card>
      </v-col>
      <v-col cols="12" sm="6">
				<v-card class="pa-2"  elevation="0">
					<v-img
						:src="require('../assets/salud/ejercicio_fisico.jpg')"
						class="white--text align-center"
						contain
					>
						<div class="text-center">
							<v-btn
								to="/salud/exercise"
                class="white--text px-10 py-8 salud-btn"
								outlined
							>
								Ejercicio físico
							</v-btn>
						</div>
					</v-img>
				</v-card>
      </v-col>
      <v-col cols="12" sm="6">
				<v-card class="pa-2"  elevation="0">
					<v-img
						:src="require('../assets/salud/gestion_emociones.jpg')"
						class="white--text align-center"
						contain
					>
						<div class="text-center">
							<v-btn
								to="/salud/emotions"
                class="white--text px-10 py-8 salud-btn"
								outlined
							>
								Gestión de emociones
							</v-btn>
						</div>
					</v-img>
				</v-card>
      </v-col>
      <v-col cols="12" sm="6">
				<v-card class="pa-2"  elevation="0">
					<v-img
						:src="require('../assets/salud/falsas_creencias.jpg')"
						class="white--text align-center"
						contain
					>
						<div class="text-center">
							<v-btn
								to="/salud/beliefs"
								class="white--text px-10 py-8 salud-btn"
								outlined
							>
								Falsas creencias acerca <br />
								del cáncer y tratamiento
							</v-btn>
						</div>
					</v-img>
				</v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="pa-2"  elevation="0">
          <v-img
              :src="require('../assets/salud/guias_esenciales.jpg')"
              class="white--text align-center"
              contain
          >
            <div class="text-center">
              <v-btn
                  to="/salud/guides"
                  class="white--text px-10 py-8 salud-btn"
                  outlined
              >
                Guias Médicas Esenciales
              </v-btn>
            </div>
          </v-img>
        </v-card>
      </v-col>
		</v-row>
	</div>
</template>

<script>
import TitlePage from '@/components/TitlePage'

export default {
	name: 'SaludView',
	components: { TitlePage },
}
</script>

<style scoped>
.salud-btn {
	background-color: rgb(0, 0, 0, 0.4);
}
</style>
