<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#546ca4'"
			:title="'ESTAR AL DÍA'"
			:subtitle="'Información esencial'"
			:img="'informacion_blanco'"
		/>

		<div v-if="loading">
			<div class="text-center">
				<v-progress-circular
					indeterminate
					color="#546ca4"
					class="mt-6"
				></v-progress-circular>
			</div>
		</div>

		<div v-else-if="!post || error">
			<EmptyState />
		</div>

		<div class="news-container" v-else>
			<v-img
				class="white--text align-end"
				height="300px"
				:src="post.getImgUrl()"
			>
			</v-img>

			<v-card-subtitle class="pb-0">
				{{ post.getDate() }}
			</v-card-subtitle>


      <h1 class="px-4 page-title information-color">{{ post.title }}</h1>


			<v-card-text class="text--primary" v-html="post.content"> </v-card-text>
		</div>
	</div>
</template>

<script>
import { getPost } from '@/utils/getPosts'
import TitlePage from '@/components/TitlePage.vue'
import EmptyState from '@/components/EmptyState.vue'

export default {
	name: 'NewsView',
	data() {
		return {
			post: {},
			loading: true,
			error: false,
		}
	},
	components: {
		TitlePage,
		EmptyState,
	},
	async mounted() {
		const { id } = this.$route.params
		const result = await getPost(id)

		if (result != 'Error') this.post = result[0]
		else this.error = true

		this.loading = false
	},
}
</script>

<style scoped>
.news-title {
	word-break: keep-all;
	color: #546ca4;
	font-size: 35px;
	font-weight: 700;
  line-height: 1.1;
}
.news-container >>> p {
  font-size: 16px;
  line-height: 1.5;
}
.news-container >>> img {
  width: 100%;
}
</style>
