<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#b29e38'"
			:title="'CONSULTAS Y FORO'"
			:subtitle="'Comparte experiencias y resuelve dudas'"
			:img="'dialogo_blanco'"
		/>

		<v-row class="mt-2" no-gutters>
			<div class="col-md-6 col-sm-6 col-xs-12">
        <v-card class="ma-2" elevation="0">
					<v-img
						:src="require('../assets/contact/foro.jpg')"
						class="white--text align-center"
						height="200px"
					>
						<div class="text-center">
							<v-btn
								href="https://forosupervivientescancer.es/"
								target="_blank"
								class="white--text px-16 py-8 salud-btn"
								outlined
							>
								Foro
							</v-btn>
						</div>
					</v-img>
				</v-card>
			</div>
			<div class="col-md-6 col-sm-6 col-xs-12">
        <v-card class="ma-2" elevation="0">
					<v-img
						:src="require('../assets/contact/buzon_consultas.jpg')"
						class="white--text align-center"
						height="200px"
					>
						<div class="text-center">
							<v-btn
								to="/contact/questions"
								class="white--text px-16 py-8 salud-btn"
								outlined
							>
								Buzón de Consultas
							</v-btn>
						</div>
					</v-img>
				</v-card>
			</div>
		</v-row>
	</div>
</template>

<script>
import TitlePage from '@/components/TitlePage'

export default {
	components: { TitlePage },
	name: 'ContactView.vue',
}
</script>

<style scoped>
.salud-btn {
	background-color: rgb(0, 0, 0, 0.4);
}
</style>
