<template>
	<v-list-item @click="overlay = !overlay">
		<v-list-item-avatar>
			<v-icon class="grey lighten-1" dark> fa-hospital </v-icon>
		</v-list-item-avatar>
		<v-list-item-content>
			<v-list-item-title v-text="hospital.name"></v-list-item-title>
			<v-list-item-subtitle
				v-text="`${hospital.city} (${hospital.province})`"
			></v-list-item-subtitle>
		</v-list-item-content>
		<v-list-item-action></v-list-item-action>
		<v-overlay :value="overlay">
			<v-card elevation="1" light flat width="400px">
				<v-card-text>
					<div>Información</div>
					<p class="text-h5 text--primary">
						{{ hospital.name }}
					</p>
					<p>
						{{ hospital.address }} <br />
						<small>{{ hospital.city }} ({{ hospital.province }})</small>
					</p>
					<p>{{ hospital.telephone }}</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn outlined rounded text :href="`tel:${hospital.telephone}`">
						<v-icon class="mr-2" small>fa-phone</v-icon>
						Llamar
					</v-btn>
					<v-btn
						:href="`https://www.google.es/maps/search/${hospital.search}`"
						target="_blank"
						outlined
						rounded
					>
						<v-icon class="mr-2" small>fa-map</v-icon>
						Mapa
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-overlay>
	</v-list-item>
</template>

<script>
export default {
	name: 'HospitalItem',
	data() {
		return {
			overlay: false,
			hospital: {},
		}
	},
	props: {
		hospitalProp: Object,
	},
	created() {
		const hospitalName = this.hospitalProp.name
		const hospitalAddress = this.hospitalProp.address
		const hospitalCity = this.hospitalProp.city
		const hospitalProvince = this.hospitalProp.province

		this.hospital = {
			...this.hospitalProp,
			search:
				hospitalName.replaceAll(' ', '+') +
				'+' +
				hospitalAddress.replaceAll(' ', '+') +
				'+' +
				hospitalCity.replaceAll(' ', '+') +
				'+' +
				hospitalProvince.replaceAll(' ', '+'),
		}
	},
}
</script>

<style scoped></style>
