import { API_URL } from './globals'
import axios from 'axios'
import { Post } from '@/model/Post'

const getPosts = (page) => {
	return axios
		.get(`${API_URL}/posts?page=${page}`)
		.then((response) => {
			{
				const { data } = response
				const totalPages = Math.ceil(data['hydra:totalItems'] / 30)
				const posts = Post.createCollectionFromAPI(data['hydra:member'])

				return [posts, totalPages]
			}
		})
		.catch((err) => {
			console.log(err)
			return 'Error'
		})
}

const getPost = (id) => {
	return axios
		.get(`${API_URL}/posts/${id}`)
		.then((response) => {
			return Post.createCollectionFromAPI([response.data])
		})
		.catch((err) => {
			console.log(err)
			return 'Error'
		})
}

export { getPost, getPosts }
