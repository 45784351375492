<template>
	<div>
		<v-row class="text-center" no-gutters>
			<v-col cols="12">
				<v-img
					:src="require('../assets/logo_supervivientes.png')"
					contain
					class="my-5"
					height="170"
				/>
			</v-col>
		</v-row>

		<section class="home-util">
			<img
				:src="require('../assets/home/FONDO_SUPERVIVIENTES.jpg')"
				class="home-util-img"
			/>

			<div class="home-util-content">
				<p>
					Esta aplicación es útil para todas las personas que hayan padecido
					algún tipo de cáncer o tumor en la infancia o adolescencia.
				</p>
				<p>
					La APP es el resultado del trabajo conjunto de un grupo de
					supervivientes y profesionales sanitarios y del área de trabajo
					social.
				</p>
			</div>

			<router-link to="/information/util" class="home-utils-title"
				>¿PARA QUIÉN ES ÚTIL ESTA APP?</router-link
			>
		</section>

		<v-row no-gutters>
			<div class="col-md-6 col-sm-6 col-xs-12">
				<v-card elevation="0" to="/salud">
					<v-img
						:src="require('../assets/home/salud.jpg')"
						class="white--text align-center"
						contain
					>
					</v-img>
					<v-card-text class="py-0 px-6">
						<div class="pa-4" style="border: 1px solid black">
							<h1 class="text-center home-title" style="color: #43955d">SALUD</h1>
							<div class="home-subtitle text-center">Cuida tu salud física y emocional</div>
						</div>
					</v-card-text>
				</v-card>
			</div>
			<div class="col-md-6 col-sm-6 col-xs-12">
				<v-card elevation="0" to="/information">
					<v-img
						:src="require('../assets/home/estar_al_dia.jpg')"
						class="white--text align-center"
						contain
					>
					</v-img>
          <v-card-text class="py-0 px-6">
						<div class="pa-4" style="border: 1px solid black">
							<h1 class="text-center home-title" style="color: #546ca4">ESTAR AL DÍA</h1>
							<div class="home-subtitle text-center">
								Información esencial para un superviviente
							</div>
						</div>
					</v-card-text>
				</v-card>
			</div>
			<div class="col-md-6 col-sm-6 col-xs-12">
				<v-card elevation="0" to="/contact">
					<v-img
						:src="require('../assets/home/foro.jpg')"
						class="white--text align-center"
						contain
					>
					</v-img>
          <v-card-text class="py-0 px-6">
						<div class="pa-4" style="border: 1px solid black">
							<h1 class="text-center home-title" style="color: #b29e38">
								CONSULTAS Y FORO
							</h1>
							<div class="text-center home-subtitle">
								Comparte experiencias y resuelve dudas
							</div>
						</div>
					</v-card-text>
				</v-card>
			</div>
    </v-row>

	</div>
</template>

<script>
export default {
	name: 'HelloWorld',
}
</script>

<style scoped>
.home-util {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 0 40px 0;
	background: url('../assets/home/FONDO_SUPERVIVIENTES.jpg');
	background-repeat: no-repeat;
	background-size: cover;
}

.home-util-img {
	width: 100%;
	display: none;
}

.home-util-content {
	top: 0;
	width: 90%;
	color: #fff;
	text-align: center;
	font-size: 18px;
	margin: 30px 0;
	font-weight: 500;
}

.home-util-content p {
	margin: 0;
	text-shadow: 1px 1px 7px #0004;
}

.home-utils-title {
	bottom: 0;
	background-color: #000;
	color: #fff;
	padding: 10px;
	font-size: 20px;
	text-decoration: none;
}

.home-subtitle {
  font-family: 'Roboto Slab', sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  padding-top: 8px;
}

.home-title {
  font-family: 'Roboto Slab', sans-serif;
}

@media screen and (min-width: 600px) {
	.home-util {
		background: none;
		max-height: 300px;
		overflow: hidden;
		justify-content: center;
	}

	.home-util-img {
		display: block;
	}

	.home-util-content {
		position: absolute;
		font-size: 20px;
		width: 80%;
		top: 20px;
	}

	.home-utils-title {
		position: absolute;
		font-size: 22px;
	}
}
</style>
