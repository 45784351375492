import {PageFile} from "@/model/PageFile";
import {PageLink} from "@/model/PageLink";
import {PageSection} from "@/model/PageSection";

export class ContentPage {
  constructor(id, name, content, headerImage, pageFiles, pageLinks, pageSections) {
    this.id = id;
    this.name = name;
    this.content = content;
    this.headerImage = headerImage;
    this.pageFiles = pageFiles;
    this.pageLinks = pageLinks;
    this.pageSections = pageSections;
  }

  static createFromAPI(element){
    let pageFiles = PageFile.createFromAPI(element[0].pageFiles);
    let pageLinks = PageLink.createFromAPI(element[0].pageLinks);
    let pageSections = PageSection.createFromAPI(element[0].pageSections);
    return new ContentPage(element[0].id, element[0].name, element[0].content, element[0].headerImage, pageFiles, pageLinks, pageSections);
  }
}
