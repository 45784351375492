<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#546ca4'"
			:title="'ESTAR AL DÍA'"
			:subtitle="'Información esencial'"
			:img="'informacion_blanco'"
		/>

    <section class="pa-4">
      <h1 class="page-title information-color">
        Catálogo nacional de hospitales
      </h1>

      <p>
        Puedes consultar toda la información disponible de los hospitales de España en el catálogo nacional de hospitales actualizado por el Ministerio de Sanidad en <a class="external-link information-color" href="https://www.sanidad.gob.es/ciudadanos/prestaciones/centrosServiciosSNS/hospitales/home.htm" target="_blank">este enlace</a>
      </p>
      <p>
        Si lo prefieres puedes <a class="external-link information-color"  href="https://private.supervivientescancerinfantil.es/assets/listado.pdf" target="_blank">descargar la guía de hospitales</a>.
      </p>
      <p>
      A continuación puedes consultar todos los centros hospitalarios de la Comunidad Valenciana.
      </p>
    </section>

		<v-text-field
			class="mt-2"
			label="Buscar"
			filled
			v-model="search"
		></v-text-field>

		<div class="hospital-selectors">
			<v-select
				@change="setProvince"
				:items="provinces"
				filled
				label="Provincias"
			></v-select>

			<v-select
				@change="setCity"
				:items="cities"
				filled
				label="Ciudades"
			></v-select>
		</div>

		<div v-if="loading">
			<div class="text-center">
				<v-progress-circular
					indeterminate
					color="#546ca4"
					class="mt-6"
				></v-progress-circular>
			</div>
		</div>

		<div v-else-if="allHospitals.length <= 0 || error">
			<EmptyState />
		</div>

		<div v-else>
			<v-list class="pt-0" two-line v-if="hospitals.length > 0">
				<v-list-item-group>
					<template v-for="(hospital, index) in hospitals">
						<HospitalItem :key="hospital.id" :hospitalProp="hospital" />
						<v-divider
							v-if="index < hospitals.length - 1"
							:key="index"
						></v-divider>
					</template>
				</v-list-item-group>
			</v-list>

			<div v-else>
				<EmptyState />
			</div>
			<v-pagination
				v-if="totalPages > 1"
				v-model="page"
				:length="totalPages"
			></v-pagination>
		</div>
	</div>
</template>

<script>
import { getHospitals, getProvinces, getCities } from '@/utils/getHospitals'
import TitlePage from '@/components/TitlePage.vue'
import HospitalItem from '@/components/HospitalItem.vue'
import EmptyState from '@/components/EmptyState.vue'

export default {
	name: 'HospitalsView',
	components: {
		TitlePage,
		HospitalItem,
		EmptyState,
	},
	data() {
		return {
			loading: true,
			error: false,
			hospitals: null,
			cities: ['Todas'],
			provinces: ['Todas'],
			city: '',
			province: '',
			search: '',
			totalPages: 0,
			page: 1,
		}
	},
	async created() {
		await getHospitals(this.page, '', '', '')
			.then((res) => {
				this.setApiData(res)
			})
			.catch((err) => {
				console.log(err)
				this.error = true
			})
			.finally(() => {
				this.loading = false
			})

		getProvinces().then((res) => {
			this.provinces = ['Todas', ...res]
		})
	},
	methods: {
		filterHospitals() {
			this.loading = true

			getHospitals(this.page, this.province, this.city, this.search)
				.then((res) => {
					this.setApiData(res)
				})
				.catch((err) => {
					console.log(err)
					this.error = true
				})
				.finally(() => {
					this.loading = false
				})
		},
		setCity(value) {
			if (value == 'Todas') {
				this.city = ''
			} else {
				this.city = value
			}
		},
		async setProvince(value) {
			if (value == 'Todas') {
				this.province = ''
				this.city = ''
				this.cities = []
			} else {
				this.city = ''
				this.cities = ['Todas', ...(await getCities(value))]

				this.province = value
			}
		},
		setApiData(res) {
			const { data, hospitals } = res
			this.totalPages = Math.ceil(data['hydra:totalItems'] / 30)

			this.hospitals = hospitals
			this.allHospitals = hospitals
			this.filteredHospitals = hospitals
			this.loading = false

			//GET THE PROVINCES
			this.hospitals.forEach((element) => {
				const province = element.province

				if (!this.provinces.includes(province)) {
					this.provinces = [...this.provinces, province]
				}
			})
		},
	},
	watch: {
		province() {
			this.filterHospitals()
		},

		city() {
			this.filterHospitals()
		},

		search() {
			this.filterHospitals()
		},

		page() {
			this.loading = true
			getHospitals(this.page, this.province, this.city, this.search)
				.then((res) => {
					this.setApiData(res)
				})
				.catch((err) => {
					console.log(err)
					this.error = true
				})
				.finally(() => {
					this.loading = false
				})
		},
	},
}
</script>

<style scoped>
.hospital-selectors {
	display: grid;
	gap: 0 20px;
	grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.hospitals-section {
  padding: 24px 24px 0 24px;
  margin: auto;
}

@media screen and (min-width: 1080px) {
  .hospitals-section {
    padding: 24px 0 0 0;
  }
}
</style>
