<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#D22630'"
			:title="'AREA PRIVADA'"
			:subtitle="'Herramientas para el día a día.'"
			:img="'login_blanco'"
		/>

    <h1 class="page-title private-color px-4 pt-4">Añadir evento</h1>
    <p class="px-4">El recordatorio de esta cita o evento llegará a tu correo electrónico con 24 horas de antelación.</p>
		<v-form ref="form" @submit.prevent="save">
			<v-container>
				<v-row>
					<v-col cols="12" md="6">
						<v-text-field
							v-model="name"
							prepend-icon="fa-file"
							label="Nombre*"
							:rules="noEmptyRule"
						></v-text-field>
					</v-col>

					<v-col cols="12" md="6">
						<v-dialog
							ref="dialog"
							v-model="modal"
							:return-value.sync="date"
							persistent
							width="290px"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="date"
									label="Fecha"
									prepend-icon="fa-calendar"
									readonly
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker v-model="date" scrollable>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="modal = false">
									Cancelar
								</v-btn>
								<v-btn text color="primary" @click="$refs.dialog.save(date)">
									OK
								</v-btn>
							</v-date-picker>
						</v-dialog>
					</v-col>
					<v-col>
						<v-textarea
							name="input-7-1"
							label="Descripción"
							v-model="description"
							value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
						></v-textarea>
					</v-col>
				</v-row>
			</v-container>
		</v-form>

		<v-row>
			<v-btn
				color="green"
				fab
				dark
				fixed
				right
				bottom
				@click="save"
				:loading="loading"
			>
				<v-icon>fa-save</v-icon>
			</v-btn>
			<v-btn
				color="red"
				fab
				dark
				fixed
				right
				bottom
				style="right: 100px"
				to="/private/events"
			>
				<v-icon>fa-ban</v-icon>
			</v-btn>
		</v-row>
	</div>
</template>

<script>
import TitlePage from '@/components/TitlePage'
import { createEvent } from '@/utils/privateEvents'

export default {
	name: 'PrivateEventCreateView',
	components: { TitlePage },
	data: () => ({
		noEmptyRule: [(v) => !!v || 'Campo Requerido'],
		name: '',
		description: '',
		modal: false,
		date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
			.toISOString()
			.substr(0, 10),
		loading: false,
	}),
	methods: {
		save() {
			const form = this.$refs.form
			const validate = form.validate()

			if (validate) {
				const event = {
					name: this.name,
					details: this.description,
					date: new Date(this.date).toISOString(),
				}

				this.loading = true

				createEvent(event)
					.then(() => {
						this.$router.push('/private/events')
					})
					.catch((err) => {
						console.log(err)
					})
					.finally(() => {
						this.loading = false
					})
			}
		},
	},
}
</script>

<style scoped></style>
