<template>
  <div>
    <TitlePage
        :color="'#fff'"
        :backgroundColor="'#546ca4'"
        :title="'ESTAR AL DÍA'"
        :subtitle="'Información esencial'"
        :img="'informacion_blanco'"
    />
    <section class="pa-4">
      <h1 class="page-title information-color">
        Un proyecto hecho realidad
      </h1>
      <p>
        Esta aplicación forma parte de un conjunto de actividades y acciones inscritas en el programa de seguimiento a largo plazo para supervivientes del cáncer infantil en la Comunidad Valenciana. La APP es una realidad gracias al apoyo de la ciudadanía, que voto el proyecto en los presupuestos participativos de la Generalitat Valenciana, y al impulso y la colaboración de la Generalitat y la Conselleria de Sanitat.
      </p>
      <p>
        La aplicación, junto con el resto del programa, se orienta a facilitar a los propios supervivientes herramientas e información para mejorar la detección de secuelas y recaídas y llevar en general una vida saludable. En este caso los usuarios de la aplicación tendrán acceso a información de alto valor, podran lanzar consultas, participar en un foro de supervivientes y organizar su día a día desde un punto de vista médico y sanitario. Además estarán informados sobre todas las novedades que conciernen a su colectivo.
      </p>
      <p>
        ASPANION agradece a los organismos públicos, a los hospitales de referencia de oncología pediátrica y a sus profesinales sanitarios su colaboración para hacer realidad este proyecto.
      </p>
      <v-row align="center"
             justify="center">
        <v-col cols="6">
          <v-img
              src="../../assets/information/project/logo_generalitat.jpg"
          />
        </v-col>
        <v-col cols="6">
          <v-img
              src="../../assets/information/project/LOGO-ASPANION.jpg"
          />
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import TitlePage from '@/components/TitlePage.vue'

export default {
  name: "ProjectView",
  components: { TitlePage },
}
</script>

<style scoped>

</style>
