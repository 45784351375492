import ApiService from '@/service/api.service'

export const getMedicines = (page) => {
	return ApiService.get(`/medicines?page=${page}`).then((res) => {
		const { data } = res
		return [data['hydra:member'], data['hydra:totalItems']]
	})
}

export const getMedicine = (id) => {
	return ApiService.get(`/medicines/${id}`).then((res) => {
		let { data } = res

		return data
	})
}

export const createMedicines = (data) => {
	return ApiService.post(`/medicines`, data).then((res) => {
		const { data } = res
		return data['hydra:member']
	})
}

export const updateMedicine = (id, data) => {
	return ApiService.put(`/medicines/${id}`, data).then((res) => {
		let { data } = res

		return data
	})
}

export const deleteMedicine = (id) => {
	return ApiService.delete(`/medicines/${id}`)
}
