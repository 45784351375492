import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import SaludView from '@/views/SaludView.vue'

import NewsView from '@/views/information/NewsView'

import InformationView from '@/views/InformationView'
import ContactView from '@/views/ContactView'
import ListNewsView from '@/views/information/ListNewsView'
import TestimoniesView from '@/views/information/TestimoniesView'
import AboutView from '@/views/information/AboutView'
import CollaborateView from '@/views/information/CollaborateView'
import ContentPage from '@/components/ContentPage'
import PrivateAreaView from '@/views/PrivateAreaView'
import LoginView from '@/views/LoginView'
import NotFoundView from '@/views/NotFoundView'
import ListEventsView from '@/views/information/ListEventsView'
import EventsView from '@/views/information/EventsView'
import HospitalsView from '@/views/information/HospitalsView'
import store from '@/store'
import RegisterView from '@/views/RegisterView'

import PrivateEventsView from '@/views/private/events/PrivateEventsListView'
import PrivateEventsDetailView from '@/views/private/events/PrivateEventsDetailView'
import PrivateEventCreateView from '@/views/private/events/PrivateEventCreateView'

import PrivateMedicinesListView from '@/views/private/medicines/PrivateMedicinesListView'
import PrivateMedicineDetailView from '@/views/private/medicines/PrivateMedicineDetailView'
import PrivateMedicineCreateView from '@/views/private/medicines/PrivateMedicineCreateView'

import PrivateDoctorsListView from '@/views/private/doctors/PrivateDoctorsListView'
import PrivateDoctorDetailView from '@/views/private/doctors/PrivateDoctorDetailView'
import PrivateDoctorCreateView from '@/views/private/doctors/PrivateDoctorCreateView'

import PrivateDocumentsListView from '@/views/private/documents/PrivateDocumentsListView'
import PrivateDocumentsDetailView from '@/views/private/documents/PrivateDocumentsDetailView'
import PrivateDocumentsCreateView from '@/views/private/documents/PrivateDocumentsCreateView'

import ProfileView from '@/views/private/ProfileView'
import PrivatePassportView from '@/views/private/PrivatePassportView'
import UtilView from '@/views/information/UtilView'
import PassportView from '@/views/information/PassportView'
import QuestionsView from '@/views/contact/QuestionsView'
import ProjectView from "@/views/information/ProjectView";

Vue.use(VueRouter)

const routes = [
	{
		path: '/information/news/:id',
		name: 'newsItem',
		component: NewsView,
	},
	{
		path: '/',
		name: 'home',
		component: HomeView,
		meta: { requiresAuth: false },
	},
	{
		path: '/salud',
		name: 'salud',
		component: SaludView,
		meta: { requiresAuth: false },
	},
	{
		path: '/salud/habits',
		name: 'habits',
		component: ContentPage,
		props: { page_key: 'habitos_saludables' },
		meta: { requiresAuth: false },
	},
	{
		path: '/salud/nutrition',
		name: 'nutrition',
		component: ContentPage,
		props: { page_key: 'nutricion' },
		meta: { requiresAuth: false },
	},
	{
		path: '/salud/sexual',
		name: 'sexual',
		component: ContentPage,
		props: { page_key: 'salud_sexual' },
		meta: { requiresAuth: false },
	},
	{
		path: '/salud/exercise',
		name: 'exercise',
		component: ContentPage,
		props: { page_key: 'ejercicio_fisico' },
		meta: { requiresAuth: false },
	},
	{
		path: '/salud/emotions',
		name: 'emotions',
		component: ContentPage,
		props: { page_key: 'gestion_emociones' },
		meta: { requiresAuth: false },
	},
	{
		path: '/salud/beliefs',
		name: 'beliefs',
		component: ContentPage,
		props: { page_key: 'falsas_creencias' },
		meta: { requiresAuth: false },
	},
	{
		path: '/salud/guides',
		name: 'guides',
		component: ContentPage,
		props: { page_key: 'guias_esenciales' },
		meta: { requiresAuth: false },
	},
	{
		path: '/information',
		name: 'information',
		component: InformationView,
		meta: { requiresAuth: false },
	},
	{
		path: '/contact',
		name: 'contact',
		component: ContactView,
		meta: { requiresAuth: false },
	},
	{
		path: '/contact/questions',
		name: 'questions',
		component: QuestionsView,
		meta: { requiresAuth: false },
	},
	{
		path: '/information/about',
		name: 'about',
		component: AboutView,
		meta: { requiresAuth: false },
	},
	{
		path: '/information/collaborate',
		name: 'collaborate',
		component: CollaborateView,
		meta: { requiresAuth: false },
	},
	{
		path: '/information/news',
		name: 'listNews',
		component: ListNewsView,
		meta: { requiresAuth: false },
	},
	{
		path: '/information/util',
		name: 'util',
		component: UtilView,
		meta: { requiresAuth: false },
	},
	{
		path: '/information/testimonies',
		name: 'testimonies',
		component: TestimoniesView,
		meta: { requiresAuth: false },
	},
	{
		path: '/information/events',
		name: 'listEvents',
		component: ListEventsView,
		meta: { requiresAuth: false },
	},
	{
		path: '/information/events/:id',
		name: 'events',
		component: EventsView,
		meta: { requiresAuth: false },
	},
	{
		path: '/information/hospitals',
		name: 'hospital',
		component: HospitalsView,
		meta: { requiresAuth: false },
	},
	{
		path: '/information/passport',
		name: 'passport',
		component: PassportView,
		meta: { requiresAuth: false },
	},
	{
		path: '/information/project',
		name: 'project',
		component: ProjectView,
		meta: { requiresAuth: false },
	},
	{
		path: '/login',
		name: 'login',
		component: LoginView,
		meta: { requiresAuth: false },
	},
	{
		path: '/register',
		name: 'register',
		component: RegisterView,
		meta: { requiresAuth: false },
	},
	{
		path: '/private',
		name: 'private',
		component: PrivateAreaView,
		meta: { requiresAuth: true },
	},
	{
		path: '/private/events',
		name: 'private_events',
		component: PrivateEventsView,
		meta: { requiresAuth: true },
	},
	{
		path: '/private/events/:id',
		name: 'private_events_detail',
		component: PrivateEventsDetailView,
		meta: { requiresAuth: true },
	},
	{
		path: '/private/create-events',
		name: 'createEvent',
		component: PrivateEventCreateView,
		meta: { requiresAuth: true },
	},
	{
		path: '/private/profile',
		name: 'profile',
		component: ProfileView,
		meta: { requiresAuth: true },
	},
	{
		path: '/private/passport',
		name: 'private_passport',
		component: PrivatePassportView,
		meta: { requiresAuth: true },
	},
	{
		path: '/private/medicines',
		name: 'private_medicines',
		component: PrivateMedicinesListView,
		meta: { requiresAuth: true },
	},
	{
		path: '/private/create-medicines',
		name: 'createMedicines',
		component: PrivateMedicineCreateView,
		meta: { requiresAuth: true },
	},
	{
		path: '/private/medicines/:id',
		name: 'medicine_details',
		component: PrivateMedicineDetailView,
		meta: { requiresAuth: true },
	},
	{
		path: '/private/doctors',
		name: 'private_doctors',
		component: PrivateDoctorsListView,
		meta: { requiresAuth: true },
	},
	{
		path: '/private/create-doctors',
		name: 'createDoctor',
		component: PrivateDoctorCreateView,
		meta: { requiresAuth: true },
	},
	{
		path: '/private/doctors/:id',
		name: 'doctors_details',
		component: PrivateDoctorDetailView,
		meta: { requiresAuth: true },
	},
	{
		path: '/private/documents',
		name: 'documents',
		component: PrivateDocumentsListView,
		meta: { requiresAuth: true },
	},
	{
		path: '/private/create-documents',
		name: 'createDocuments',
		component: PrivateDocumentsCreateView,
		meta: { requiresAuth: true },
	},
	{
		path: '/private/documents/:id',
		name: 'documents_details',
		component: PrivateDocumentsDetailView,
		meta: { requiresAuth: true },
	},
	{
		path: '*',
		name: '404',
		component: NotFoundView,
		meta: { requiresAuth: false },
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0)
	var guestRoutes = ['register', 'login']
	if (to.meta.requiresAuth && !store.getters['auth/isLoggedIn']) {
		next({ path: '/login' })
	} else if (
		guestRoutes.includes(to.name) &&
		store.getters['auth/isLoggedIn']
	) {
		next({ path: '/' })
	} else {
		next()
	}
})

export default router
