<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#D22630'"
			:title="'AREA PRIVADA'"
			:subtitle="'Herramientas para el día a día.'"
			:img="'login_blanco'"
		/>

    <h1 class="page-title private-color px-4 pt-4">Añadir medicamento</h1>
		<v-form ref="form" @submit.prevent="save">
			<v-container>
				<v-row>
          <v-col cols="12">
            <v-text-field
              v-model="name"
              prepend-icon="fa-capsules"
              label="Nombre*"
              :rules="noEmptyRule"
            ></v-text-field>
          </v-col>
				</v-row>
				<v-row>
          <v-col cols="12">
            <v-textarea
              name="input-7-1"
              label="Descripción"
              v-model="description"
            ></v-textarea>
          </v-col>
				</v-row>
			</v-container>
		</v-form>

		<v-row>
			<v-btn
				color="green"
				fab
				dark
				fixed
				right
				bottom
				@click="save"
				:loading="loading"
			>
				<v-icon>fa-save</v-icon>
			</v-btn>
			<v-btn
				color="red"
				fab
				dark
				fixed
				right
				bottom
				style="right: 100px"
				to="/private/medicines"
			>
				<v-icon>fa-ban</v-icon>
			</v-btn>
		</v-row>
	</div>
</template>

<script>
import TitlePage from '@/components/TitlePage'
import { createMedicines } from '@/utils/medicines'

export default {
	name: 'PrivateMedicineCreateView',
	components: { TitlePage },
	data: () => ({
		noEmptyRule: [(v) => !!v || 'Campo Requerido'],
		name: '',
		description: '',
		modal: false,
		loading: false,
	}),
	methods: {
		save() {
			const form = this.$refs.form
			const validate = form.validate()

			if (validate) {
				const medicine = {
					name: this.name,
					description: this.description,
				}

				this.loading = true

				createMedicines(medicine)
					.then(() => {
						this.$router.push('/private/medicines')
					})
					.catch((err) => {
						console.log(err)
					})
					.finally(() => {
						this.loading = false
					})
			}
		},
	},
}
</script>

<style scoped></style>
