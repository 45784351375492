export class Event {
	constructor(id, name, content, date, img) {
		this.id = id
		this.name = name
		this.content = content
		this.date = date
		this.img = img
	}

	static createCollectionFromAPI(request) {
		var collection = []
		request.forEach((element) =>
			collection.push(
				new Event(
					element.id,
					element.name,
					element.details,
					element.date,
					element.headerImage
				)
			)
		)
		return collection
	}

	getDate() {
		const options = {
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		}
		let date = new Date(this.date)
		return date.toLocaleDateString('es-ES', options)
	}

	getImgUrl() {
		return (
			'https://private.supervivientescancerinfantil.es/uploads/global_events/' +
			this.img
		)
	}
}
