<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#546ca4'"
			:title="'ESTAR AL DÍA'"
			:subtitle="'Información esencial'"
			:img="'informacion_blanco'"
		/>
		<v-sheet class="mx-auto pa-4" color="white" elevation="0">
			<h1 class="page-title information-color" >Sobre ASPANION</h1>
			<p>
				ASPANION es la Asociación de Madres y Padres de Niñas y Niños con Cáncer
				de la Comunidad Valenciana. La entidad nace en 1985 y ofrece a los
				menores afectados y a sus madres, padres y hermanos apoyo psicológico,
				social y económico. ASPANION ofrece sus recursos y ayuda desde el
				diagnóstico y en todas las fases de la enfermedad, incluso en el duelo.
				El principal objetivo de la ONG es mejorar la calidad de vida los
				afectados.
			</p>
			<v-img
				class="my-6"
				max-height="350"
				src="../../assets/information/about/talleres.jpg"
			>
			</v-img>
			<p>
				El porcentaje de supervivientes crece año tras año y por ello ASPANION
				impulsa el programa de seguimiento a largo plazo para supervivientes del
				cáncer infantil.
			</p>
			<p>
        Los ciudadanos de la Comunidad Valenciana votaron en los presupuestos participativos de la
        Generalitat este proyecto impulsado por ASPANION al entender el impacto y la necesidad del
        mismo. Fue la propuesta más votada y su materialización permite apoyar y atender a un colectivo
        creciente.
			</p>
      <p>
        Actualmente el programa de seguimiento se desarrolla con la colaboración directa de la
        GENERALITAT VALENCIANA y la Conselleria de Sanitat y cuenta con el apoyo de los principales
        hospitales de oncología pediátrica de la Comunidad Valenciana. El programa incluye un
        seguimiento de la salud de los supervivientes para mejorar la detección de secuelas y recaídas.
      </p>
			<p>
				Es una prioridad curar a los menores enfermos de cáncer y también lo es
				cuidarles el resto de su vida para evitar que puedan desarrollar
				problemas de salud derivados de los tratamientos recibidos o del propio
				tumor o cáncer que se padeció.
			</p>
			<v-img class="my-6" src="../../assets/information/about/hospital.jpg">
			</v-img>
			<p>
				ASPANION cuenta con el SELLO DE TRANSPARENCIA de la Fundación Lealtad y
				es una ONG certificada por el instituto para la Calidad de las
				ONG-ICONG. Además sus cuentas son auditadas.
			</p>
			<p class="text-center">¿Quieres saber más de ASPANION?</p>
			<p class="text-center">Visita nuestra página web <a href="https://aspanion.es/" class="external-link information-color" target="_blank">www.aspanion.es</a></p>

      <v-row align="center"
             justify="center">
        <v-col cols="6">
          <v-img
              src="../../assets/information/project/logo_generalitat.jpg"
          />
        </v-col>
        <v-col cols="6">
          <v-img
              src="../../assets/information/project/LOGO-ASPANION.jpg"
          />
        </v-col>
      </v-row>
		</v-sheet>
	</div>
</template>

<script>
import TitlePage from '@/components/TitlePage.vue'

export default {
	name: 'AboutView',
	components: {
		TitlePage,
	},
}
</script>

<style scoped>
@media screen and (min-width: 1080px) {
	.about-content {
		padding: 24px 0;
	}
}
</style>
