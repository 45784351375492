<template>
	<div class="mt-5">
		<span style="display: block; text-align: center"
			>No se ha encontrado ningun elemento</span
		>
	</div>
</template>

<script>
export default {
	name: 'EmptyState',
}
</script>
