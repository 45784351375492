<template>
	<div>
		<TitlePage
			:color="'#fff'"
			:backgroundColor="'#546ca4'"
			:title="'ESTAR AL DÍA'"
			:subtitle="'Información esencial'"
			:img="'informacion_blanco'"
		/>

		<section class="pa-4">
			<h1 class="page-title information-color">Descubre sus ventajas</h1>

			<p>
				Esta aplicación es útil para todas las personas que hayan padecido algún
				tipo de cáncer o tumor en la infancia o adolescencia. La APP es el
				resultado del trabajo conjunto de un grupo de supervivientes y
				profesionales sanitarios y del área de trabajo social. Todos ellos
				expresaron sus necesidades y deseos para conseguir que esta plataforma,
				diseñada con la última tecnología, sea un punto de encuentro, de
				intercambio de experiencias y un referente informativo sobre la realidad
				de los supervivientes.
			</p>

			<img
				:src="require('../../assets/information/util/grupo_app_utilidad.jpg')"
				class="util-section-image"
			/>
		</section>

		<section class="pa-4">
      <h1 class="page-title information-color">¿Qué encontrarás en la aplicación?</h1>

      <h3 class="util-section-subtitle"><b>Área común</b></h3>

			<p>
				La aplicación tiene un área común en la que se vehicula amplia
				información sobre secuelas y calidad de vida. Se incluyen
				recomendaciones para cuidar la salud física y emocional y guías útiles
				que es posible descargar. Además se ha dado vida a un foro, que nace
				para facilitar el intercambio de experiencias y la conexión entre todos
				los supervivientes.
			</p>

			<img
				:src="require('../../assets/information/para-quien.png')"
				class="util-section-image"
			/>

			<p>
				La aplicación también cuenta con un buzón de consultas, un apartado de
				noticias y una agenda con las grandes citas anuales de los
				supervivientes. Se incluye información actualizada sobre el pasaporte
				del superviviente que será una realidad en Europa los próximos años. En
				definitiva el área común de la APP aspira a convertirse en un
				instrumento de uso diario y permanente para sus usuarios. Un lugar en el
				que obtener respuestas a las grandes dudas que puedan surgirles en su
				día a día.
			</p>

      <h3 class="util-section-subtitle"><b>Área privada</b></h3>

			<p>
				Cada usuario podrá tener actualizada toda su información sanitaria y
				personal en el área privada. Se ha contemplado incluso la opción de que
				puedan subir y guardar sus informes médicos, su agenda de citas con
				sistema de avisos, el registro de medicamentos con su pauta y la
				información relacionada con su tipo de cáncer o tumor. Además los
				usuarios tendrán la opción de registrar a todos sus especialistas.
			</p>

			<img
				:src="require('../../assets/information/util/area privada.jpg')"
				class="util-section-image"
			/>

			<p>
				El área privada será un motor de gestión, un elemento esencial para
				mantener una correcta vigilancia de la propia salud y poder integrar de
				forma sencilla todos los aspectos médicos relevantes en el día a día.
			</p>
		</section>
	</div>
</template>

<script>
import TitlePage from '@/components/TitlePage.vue'
export default {
	name: 'UtilView',
	components: { TitlePage },
}
</script>

<style scoped>

.util-section-subtitle {
	font-size: 20px;
	padding: 10px 0 10px 0;
	font-weight: 500;
}

.util-section-image {
	width: 100%;
	margin: 20px 0;
}

@media screen and (min-width: 1080px) {
	.util-section {
		padding: 24px 0 0 0;
	}
}
</style>
